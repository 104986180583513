import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core";
import { useLocation, useNavigate } from "react-router-dom";

const customStyles = makeStyles({
  menuBar: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
    height: "60px",
    alignItems: "center",
  },
  navbar: {
    height: "100%",
    backgroundColor: "#EDF2F9",
    boxShadow: "2px 0 5px rgba(0, 0, 0, 0.2)",
    display: "flex",
    flexDirection: "column",
    transition: "width 0.3s ease-in-out",
  },
  tabWrapper: {
    marginTop: "10px",
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  tab: {
    cursor: "pointer",
    gap: "14px",
    margin: "2px 4px",
    display: "flex",
    padding: "10px 16px",
    textAlign: "center",
    alignItems: "center",
    whiteSpace: "nowrap",
    borderRadius: "8px",
    fontWeight: "bold",
  },
  selectedTab: {
    background: "#BBDEFB",
    transition: "background 0.3s ease",
    color: "#4285F4",
  },
  unSelectedTab: {
    color: "#5E6E82",
    transition: "color 0.3s ease",
    "&:hover": {
      color: "#4285F4",
    },
  },
});

const NavBar = (props) => {
  const { fullSideBar, tabs } = props;
  const [selectedTab, setSelectedTab] = useState(null);
  const classes = customStyles();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const activeTab = tabs.find((tab) =>
      location.pathname.includes(tab.tabUrl)
    );
    setSelectedTab(activeTab);
  }, [location, tabs]);

  return (
    <div
      className={`${classes.navbar} ${fullSideBar ? "width-275" : "width-64"}`}
    >
      <div className={classes.tabWrapper}>
        {tabs.map((tab) => (
          <div
            key={tab.id}
            className={`${classes.tab} ${
              selectedTab?.id === tab.id
                ? classes.selectedTab
                : classes.unSelectedTab
            }`}
            onClick={() => {
              setSelectedTab(tab);
              navigate(tab.tabUrl);
            }}
          >
            {!!tab.icon && tab.icon}
            {fullSideBar && (
              <span style={{ display: "inline" }}>{tab.label}</span>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default NavBar;
