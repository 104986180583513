import React, { useState, useEffect } from "react";
import { doc, getDoc, setDoc } from "firebase/firestore";
import { db } from "../../../config/firebase";
import Layout from "./Layout";
import { COLLECTION, SESSION } from "../../../data/constant";

const VendorProfile = () => {
  const [vendorData, setVendorData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [vendorId, setVendorId] = useState(null);
  const [dataChanged, setDataChanged] = useState(false);

  useEffect(() => {
    const fetchVendorData = async () => {
      try {
        
        const sessionId = localStorage.getItem(SESSION.VENDOR_SESSION_ID);
        
        if (!sessionId) {
          throw new Error("Vendor session ID is not found in local storage.");
        }

        // Fetch vendor session data
        const vendorSessionDocRef = doc(db, COLLECTION.VENDOR_SESSION, sessionId);
        const vendorSessionDocSnap = await getDoc(vendorSessionDocRef);
        if (!vendorSessionDocSnap.exists()) {
          throw new Error("Vendor session document not found");
        }

        const vendorData = vendorSessionDocSnap.data();
        setVendorId(vendorData.vendorID);
        // console.log(vendorData)
        // console.log(typeof(vendorData.vendorId))
        const vendorDetailsDocRef = doc(db, COLLECTION.VENDOR,vendorData.vendorId );
        const vendorDetailsDocSnap = await getDoc(vendorDetailsDocRef);

        if (!vendorDetailsDocSnap.exists()) {
          throw new Error("Vendor details document not found");
        }

        setVendorData(vendorDetailsDocSnap.data());
        // console.log(vendorData);
        
        setLoading(false);
      } catch (error) {
        console.error("Error fetching vendor data:", error);
        setLoading(false);
      }
    };

    fetchVendorData();
  }, [dataChanged]); 
 

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!vendorData) {
    return <div>Vendor data not found</div>;
  }

  return <>
  <Layout vendorData={vendorData}  />
  
  </>;
};

export default VendorProfile;
