const inventoryProductsData = {
  productName: {
        key: "productName",
        label: "Vendor Products",
        placeHolder: "Select Vendor Product",
        type: "text",
      },
      unitPrice: {
        key: "unitPrice",
        label: "Price",
        placeHolder: "Enter Price",
        type: "number",
      },
      quantity: {
        key: "quantity",
        label: "Quantity",
        placeHolder: "Enter Quantity",
        type: "number",
        isRequired: true,
      },
      expiry: {
        key: "expiry",
        label: "Expiry",
        placeHolder: "Enter Expiry Date",
        type: "text",
        
      },
    };
    
    
    export { inventoryProductsData };
    
    