import React from "react";
import { CustomTable } from "../../../components";
import { makeStyles } from "@material-ui/core";
import { campusPodTableHeading } from "../../../assets/lib/campusPod";

const customStyle = makeStyles({
  root: {
    padding: "60px 40px",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    gap: "30px",
  },
});

const Layout = (props) => {
  const {
    campusPodData,
    isTableLoading,
    currentPage,
    totalLength,
    handlePageChange,
    handlePageSize,
    handleSearchQuery,
    handleTableSorting,
  } = props;
  const classes = customStyle();

  return (
    <div className={classes.root}>
      <CustomTable
        columns={campusPodTableHeading}
        data={campusPodData}
        isLoading={isTableLoading}
        currentPage={currentPage}
        totalLength={totalLength}
        handlePageChange={handlePageChange}
        handlePageSize={handlePageSize}
        handleTableSorting={handleTableSorting}
      />
    </div>
  );
};

export default Layout;
