import React, { useEffect, useRef } from "react";
import Layout from "./Layout";
import useTableFunction from "../../../hooks/useTableFunction";
import { COLLECTION, SESSION } from "../../../data/constant";
import { db } from "../../../config/firebase";
import { doc, getDoc } from "firebase/firestore";

const CampusPods = () => {
  const dynamicConditionCB = async () => {
    let campus = "";
    const sessionId = localStorage.getItem(SESSION.CAMPUS_SESSION_ID);
    const docRef = doc(db, COLLECTION.CAMPUS_SESSION, sessionId);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      const data = docSnap.data();
      campus = data.campusId;
    }
    return {
      column: "campus",
      operator: "==",
      query: campus,
    };
  };

  const {
    tableData,
    isTableLoading,
    currentPage,
    totalLength,
    handlePageChange,
    handlePageSize,
    handleSearchQuery,
    handleTableSorting,
    refetchTableData,
  } = useTableFunction({
    collectionPath: COLLECTION.POD,
    dynamicCondition: dynamicConditionCB,
  });

  return (
    <Layout
      campusPodData={tableData}
      isTableLoading={isTableLoading}
      currentPage={currentPage}
      totalLength={totalLength}
      handlePageChange={handlePageChange}
      handlePageSize={handlePageSize}
      handleSearchQuery={handleSearchQuery}
      handleTableSorting={handleTableSorting}
    />
  );
};

export default CampusPods;
