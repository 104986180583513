import React from "react";
import { makeStyles } from "@material-ui/core";

const customStyle = makeStyles({
  formGroup: {
    display: "flex",
    flexDirection: "column",
    gap: "10px",
  },
  formLabel: {
    fontSize: "16px",
    fontWeight: "bold",
  },
  formInput: {
    padding: "16px",
    border: "1px solid #ccc",
    borderRadius: "4px",
    fontSize: "18px",
  },
});

const SingleInputField = (props) => {
  const { content, value, isEditable, getInputData, errorFields } = props;
  const classes = customStyle();

  return (
    <div className={classes.formGroup}>
      <span className={classes.formLabel}>
        {content.label} :{" "}
        {content.hasOwnProperty("isRequired") && content?.isRequired && (
          <span style={{ color: "red" }}>*</span>
        )}{" "}
        {errorFields && errorFields.includes(content.key) && (
          <span style={{ color: "red", fontSize: "14px" }}>Required</span>
        )}
      </span>
      <input
        className={`${classes.formInput} ${
          errorFields && errorFields.includes(content.key)
            ? "error-input-color"
            : ""
        }`}
        type={content.type}
        placeholder={content.placeHolder}
        value={value}
        name={content.key}
        onChange={getInputData}
        disabled={!isEditable}
      />
    </div>
  );
};

SingleInputField.defaultProps = {
  content: { label: "", type: "text", placeholder: "", name: "" },
  value: "",
  isEditable: true,
  getInputData: () => {},
};

export default SingleInputField;
