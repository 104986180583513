  import React from "react";
  import Layout from "./Layout";
  import { useState } from "react";
  import useTableFunction from "../../../hooks/useTableFunction";
  import { COLLECTION, COLLECTION_COUNTER } from "../../../data/constant";
  import { podData } from "../../../assets/lib/pod";
  import InventoryModal from "./InventoryModal";
  import {
    createInitialForm,
    fetchLatestId,
    updateLatestId,
    validateRequiredField,
  } from "../../../common/common";
  import { addDoc, collection } from "firebase/firestore";
  import { db } from "../../../config/firebase";
  import {doc, getDoc} from 'firebase/firestore';
  import { useEffect } from "react";

  const InventoryManagement = () => {
    const [podState, setPodState] = useState(createInitialForm(podData));
    const [errorFields, setErrorFields] = useState([]);
    const [submitLoading, setSubmitLoading] = useState(false);
    const [isFetchingPodNum, setIsFetchingPodNum] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [errorOnFetch, setErrorOnFetch] = useState(false);
    const [inventoryData, setInventoryData] = useState([]);
    const [selectedItemData, setSelectedItemData] = useState(null);

   

    const fetchInventoryData = async (podNumber) => {
      try {
        // console.log(podNumber);
          const inventoryRef = doc(db,COLLECTION.INVENTORY,podNumber);
          const inventorySnap=await getDoc(inventoryRef);
          const inventoryData=inventorySnap.data();
          
          // console.log(inventoryData);
          setInventoryData(inventoryData);
          // return inventoryData;
      } catch (error) {
          console.error("Error fetching inventory data:", error);
          return []; 
      }
  };
// console.log("yeah",inventoryData);
  
    // console.log(fetchInventoryData("POD00012"));


    const handleOpenModal = (itemData) => {
      setIsModalOpen(true);
      setSelectedItemData(itemData);
    };

    // Function to handle closing modal
    const handleCloseModal = () => {
      setIsModalOpen(false);
      setSelectedItemData(null); 
    };

    
    const getPodInput = (event) => {
      const name = event.target.name;
      let value = event.target.value;

      const inputType = event.target.type;
      if (value && inputType === "number") {
        value = parseInt(value);
      }
      if (errorFields.includes(name) && ![undefined, null, ""].includes(value)) {
        setErrorFields((prevState) => prevState.filter((key) => key !== name));
      }
      setPodState((prevState) => ({ ...prevState, [name]: value }));
    };

    const fetchPodNumber = async () => {
      setIsFetchingPodNum(true);
      const latestId = await fetchLatestId(COLLECTION_COUNTER.POD);
      setPodState((prevState) => ({
        ...prevState,
        [podData.podNumber.key]: latestId,
      }));
      setIsFetchingPodNum(false);
    };
    const openModal = () => {
      setIsModalOpen(true);
    };
    
    // useEffect(() => {
    //   fetchInventoryData();
    // }, []); 

    const {
      tableData,
      isTableLoading,
      currentPage,
      totalLength,
      handlePageChange,
      handlePageSize,
      handleSearchQuery,
      handleTableSorting,
      refetchTableData,
    } = useTableFunction({
      collectionPath: COLLECTION.POD,
      defaultOrderBy: podData.podNumber.key,
    });

    return (
      <>
      <Layout
      openModal={openModal}
        podTableData={tableData}
        isTableLoading={isTableLoading}
        currentPage={currentPage}
        totalLength={totalLength}
        handlePageChange={handlePageChange}
        handlePageSize={handlePageSize}
        handleSearchQuery={handleSearchQuery}
        handleTableSorting={handleTableSorting}
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        podState={podState}
        getPodInput={getPodInput}
        errorFields={errorFields}
        fetchPodNumber={fetchPodNumber}
        fetchInventoryData={fetchInventoryData}
        loadingModal={isFetchingPodNum || submitLoading}
      />
      <InventoryModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} itemData={inventoryData} />
      </>
      
    );
  };

  export default InventoryManagement;


