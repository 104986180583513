import React, { useState } from "react";
import Layout from "./Layout";
import { createInitialForm } from "../../../common/common";
import { contactUsData } from "../../../assets/lib/contactUs";

const ContactUs = () => {
  const [contactUsState, setContactUsState] = useState(
    createInitialForm(contactUsData)
  );

  const getContactUsInput = (event) => {
    const name = event.target.name;
    let value = event.target.value;
    const inputType = event.target.type;
    if (value && inputType === "number") {
      value = parseInt(value);
    }
    // if (errorFields.includes(name) && ![undefined, null, ""].includes(value)) {
    //   setErrorFields((prevState) => prevState.filter((key) => key !== name));
    // }
    setContactUsState((prevState) => ({ ...prevState, [name]: value }));
  };

  return (
    <Layout
      contactUsState={contactUsState}
      getContactUsInput={getContactUsInput}
    />
  );
};

export default ContactUs;
