const userData = {
  userId: {
    key: "userId",
    label: "User ID",
    placeHolder: "Enter User Id",
    type: "text",
  },
  userName: {
    key: "userName",
    label: "User Name",
    placeHolder: "Enter User name",
    type: "text",
    isRequired: true,
  },
  GSTNumber: {
    key: "GSTNumber",
    label: "GST Number",
    placeHolder: "Enter GST Number",
    type: "text",
    isRequired: true,
  },
  phoneNumber: {
    key: "phoneNumber",
    label: "Phone Number",
    placeHolder: "Enter Phone number",
    type: "number",
    isRequired: true,
  },
  userAddress: {
    key: "userAddress",
    label: "Address",
    placeHolder: "Enter User address",
    type: "text",
  },
  location: {
    key: "location",
    label: "Location",
    placeHolder: "Enter Google Map Location",
    type: "text",
  },
  zone: {
    key: "zone",
    label: "Zone",
    placeHolder: "Enter Zone",
    type: "text",
  },
  rent: {
    key: "rent",
    label: "Rent",
    placeHolder: "Enter Rent",
    type: "number",
  },
  noOfPods: {
    key: "noOfPods",
    label: "Number Of Pods",
    placeHolder: "Enter Number Of Pods",
    type: "number",
    defaultValue: 0,
  },
  commission: {
    key: "commission",
    label: "Commission %",
    placeHolder: "Enter Commission percentage",
    type: "number",
  },
  contactPersonName: {
    key: "contactPersonName",
    label: "Name",
    placeHolder: "Enter name",
    type: "text",
  },
  contactPersonPhone: {
    key: "contactPersonPhone",
    label: "Phone Number",
    placeHolder: "Enter Phone Number",
    type: "number",
  },
};

const userTableHeading = [
  {
    key: "userId",
    label: "User ID",
  },
  {
    key: "userName",
    label: "Username",
  },
  {
    key: "phoneNumber",
    label: "Phone No.",
  },
  {
    key: "totalTransactions",
    label: "Total Transactions",
  },
  {
    key: "totalTransactionsINR",
    label: "Total Transactions INR",
  },
  {
    key: "walletAmount",
    label: "Wallet Amount",
  },
];

export { userData, userTableHeading };
