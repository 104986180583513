import React from "react";
import Layout from "./Layout";
import { useState } from "react";
import { useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom/dist";
import { addDoc, collection, doc, getDoc, updateDoc } from "firebase/firestore";
import { db } from "../../../config/firebase";
import {
  createInitialForm,
  fetchLatestId,
  updateLatestId,
  validateRequiredField,
} from "../../../common/common";
import { campusData } from "../../../assets/lib/campus";
import { COLLECTION, COLLECTION_COUNTER } from "../../../data/constant";
import { routes } from "../../../routing/routes";

const CampusInfo = () => {
  const { campusDocId } = useParams();
  const navigate = useNavigate();
  const [isEdit, setIsEdit] = useState(false);
  const [isNewAdd, setIsNewAdd] = useState(true);
  const { state: locationState } = useLocation();
  const [campusState, setCampusState] = useState(createInitialForm(campusData));
  const [errorFields, setErrorFields] = useState([]);
  const [fetchLoading, setFetchLoading] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [errorOnFetch, setErrorOnFetch] = useState(false);

  const handleSubmitModal = async () => {
    const error = validateRequiredField(campusData, campusState);
    setErrorFields(error);
    setSubmitLoading(true);
    if (error.length === 0) {
      try {
        if (isEdit && campusDocId) {
          // save edited data
          const docRef = doc(db, COLLECTION.CAMPUS, campusDocId);
          await updateDoc(docRef, campusState);
        } else {
          // Add new data
          const campusCollectionRef = collection(db, COLLECTION.CAMPUS);
          await addDoc(campusCollectionRef, campusState);
          await updateLatestId(COLLECTION_COUNTER.CAMPUS, campusState.campusId);
        }
        setSubmitLoading(false);
        navigate(routes.CAMPUS_MANAGEMENT);
      } catch (error) {
        setSubmitLoading(false);
        console.log("error", error);
        setErrorOnFetch(true);
      }
    }
  };

  const getCampusInput = (event) => {
    // If the page is open in view state
    if (!isEdit && !isNewAdd) {
      return;
    }
    const name = event.target.name;
    let value = event.target.value;
    const inputType = event.target.type;
    if (value && inputType === "number") {
      value = parseInt(value);
    }
    if (errorFields.includes(name) && ![undefined, null, ""].includes(value)) {
      setErrorFields((prevState) => prevState.filter((key) => key !== name));
    }
    setCampusState((prevState) => ({ ...prevState, [name]: value }));
  };

  useEffect(() => {
    setIsEdit(locationState?.editCampus || false);
    setIsNewAdd(locationState?.addCampus || false);
    const fetchingInfo = async () => {
      setFetchLoading(true);
      try {
        if (locationState?.addCampus) {
          // If only add data then fetch the id
          const latestId = await fetchLatestId(COLLECTION_COUNTER.CAMPUS);
          setCampusState((prevState) => ({
            ...prevState,
            [campusData.campusId.key]: latestId,
          }));
        } else {
          // For view and edit data
          const docRef = doc(db, COLLECTION.CAMPUS, campusDocId);
          const docSnap = await getDoc(docRef);

          if (docSnap.exists()) {
            const storedData = docSnap.data();
            setCampusState((prevState) => {
              const stateData = { ...prevState };
              for (const key in stateData) {
                if (storedData.hasOwnProperty(key)) {
                  stateData[key] = storedData[key];
                }
              }
              return stateData;
            });
          } else {
            throw new Error(
              "Unexpected error occurred the id does not exist in the collection"
            );
          }
        }
      } catch (error) {
        console.log("error", error);
        setErrorOnFetch(true);
      }
      setFetchLoading(false);
    };
    fetchingInfo();
  }, []);

  return (
    <>
      <Layout
        isNewAdd={isNewAdd}
        isEdit={isEdit}
        setIsEdit={setIsEdit}
        getCampusInput={getCampusInput}
        campusState={campusState}
        handleSubmitModal={handleSubmitModal}
        errorFields={errorFields}
        fetchLoading={fetchLoading}
        submitLoading={submitLoading}
        errorOnFetch={errorOnFetch}
      />
    </>
  );
};

export default CampusInfo;
