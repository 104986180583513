import KitchenIcon from "@mui/icons-material/Kitchen";
import LocationCityIcon from "@mui/icons-material/LocationCity";
import AccountBoxIcon from '@mui/icons-material/AccountBox';

import { routes } from "../routing/routes";

export const UserNavTabs = [
 
  {
    id: "userTransaction",
    label: "Transaction",
    icon: <LocationCityIcon sx={{ fontSize: 25 }} />,
    tabUrl: routes.USER_TRANSACTION,
  },
  
  {
    id: "userWallet",
    label: "Wallet",
    icon: <KitchenIcon sx={{ fontSize: 25 }} />,
    tabUrl: routes.WALLET,
  },
  {
    id: "userProfile",
    label: "Profile",
    icon: <AccountBoxIcon sx={{ fontSize: 25 }} />,
    tabUrl: routes.USER_PROFILE,
  },
];
