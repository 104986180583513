const actionTypes = {
  FETCH_USER_REQUEST: "FETCH_USER_REQUEST",
  FETCH_USER_SUCCESS: "FETCH_USER_SUCCESS",
  FETCH_USER_ERROR: "FETCH_USER_ERROR",

  FETCH_POD_REQUEST: "FETCH_POD_REQUEST",
  FETCH_POD_SUCCESS: "FETCH_POD_SUCCESS",
  FETCH_POD_ERROR: "FETCH_POD_ERROR",
};

export default actionTypes;
