import StorefrontIcon from "@mui/icons-material/Storefront";
import InventoryIcon from "@mui/icons-material/Inventory";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import CategoryIcon from '@mui/icons-material/Category';

import { routes } from "../routing/routes";

export const VendorNavTabs = [
  {
    id: "vendorPods",
    label: "Vendor Pods",
    icon: <StorefrontIcon sx={{ fontSize: 25 }} />,
    tabUrl: routes.VENDOR_PODS,
  },
  {
    id: "vendorManageInventory",
    label: "Manage Inventory",
    icon: <InventoryIcon sx={{ fontSize: 25 }} />,
    tabUrl: routes.VENDOR_MANAGE_INVENTORY,
  },
  {
    id: "vendorProfile",
    label: "Vendor Profile",
    icon: <AccountCircleIcon sx={{ fontSize: 25 }} />,
    tabUrl: routes.VENDOR_PROFILE,
  },
  {
    id: "manageProduts",
    label: "Manage Products",
    icon: <CategoryIcon sx={{ fontSize: 25 }} />,
    tabUrl: routes.VENDOR_MANAGE_PRODUCTS,
  }
];
