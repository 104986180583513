import React from "react";
import { makeStyles } from "@material-ui/core";
import { podHomeDetails } from "../../../assets/lib/home";
import GoPods from "../../../assets/Images/goPods.jpeg";
import GoPodsUltra from "../../../assets/Images/goPodsUltra.jpeg";
import GoPodsBook from "../../../assets/Images/goPodsBooks.jpg";

const customStyles = makeStyles({
  root: {
    width: "90%",
    maxWidth: "1216px",
    display: "flex",
    marginRight: "auto",
    marginLeft: "auto",
    display: "flex",
    flexDirection: "column",
    fontFamily: "Manrope",  
  },
  heading: {
    padding: "15px 15px 0px",
    width: "100%",
    color: "#271340",
    fontSize: "36px",
    fontWeight: "600",
    display: "flex",
    justifyContent: "center",
  },
  content: {
    fontSize: "18px",
    fontWeight: 500,
    lineHeight: "31px",
    color: "rgba(39, 19, 64, 0.8)",
  },
  podDetails: {
    marginTop: "50px",
    padding: "10px",
    display: "flex",
  },
  podWrapper: {
    padding: "10px",
    width: "33.33%",
    gap: "20px",
    display: "flex",
    flexDirection: "column",
  },
  podLabel: {
    color: "#271340",
    fontSize: "20px",
    fontWeight: 600,
    lineHeight: "1.2",
  },
  snackDetailsWrapper: {
    paddingTop: "120px",
    display: "flex",
    flexDirection: "column",
    maxWidth: "700px",
  },
  sideHeading: {
    padding: "0px 10px",
    color: "#271340",
    fontSize: "36px",
    fontWeight: 600,
  },
  snackDetail: {
    padding: "30px 10px",
    gap: "18px",
    fontSize: "18px",
    fontWeight: 500,
    lineHeight: 1.7,
  },
  snackP: {
    color: "rgba(39, 19, 64, 0.8)",
  },
   // Media query for iPhone 13 Pro
  '@media (max-width: 390px)': {
    heading: {
      fontSize: "24px",
      padding: "10px 0",
    },
    content: {
      fontSize: "16px",
      lineHeight: "24px",
    },
    podDetails: {
      flexDirection: "column",
      marginTop: "30px",
      marginLeft:"-20px"
    },
    podWrapper: {
      width: "100%", // Make pods full-width
      padding: "5px",
    },
    podLabel: {
      fontSize: "18px",
     
    },
    snackDetailsWrapper: {
      paddingTop: "60px",
      maxWidth: "100%", // Use full width
    },
    sideHeading: {
      fontSize: "28px",
    },
    snackDetail: {
      padding: "10px 5px",
      fontSize: "16px",
    },
  },
});

const Product = () => {
  const classes = customStyles();

  return (
    <div className={classes.root}>
      <div className={classes.heading}>Products</div>
      <div className={classes.content}>
        <p>
          We have engineered our stores to be as SIMPLE as possible. This meant
          playing around with materials and geometry from ground’s up! A custom
          designed electronic chip to make sure communication with the cloud is
          seamless, an interface to make sure a kindergartener to your
          grandparents can understand using our machines and use it even without
          your cell phone with you. The materials we have used were never being
          used for application like this before.
        </p>
        <p>
          Being SIMPLE simply means eliminating every aspect of transaction that
          is not necessary. Hence, all the work is done behind the scenes, while
          you can GO back to being awesome!
        </p>
      </div>
      <div className={classes.podDetails}>
        {podHomeDetails.map((pod) => (
          <div className={classes.podWrapper} key={pod.id}>
            <div className={classes.podLabel}>{pod.label}</div>
            <img
              src={
                pod.id === "goPodBasic"
                  ? GoPods
                  : pod.id === "goPodUltra"
                  ? GoPodsUltra
                  : GoPodsBook
              }
              alt={pod.label}
              width={358}
            />
            <ul>
              {!!pod.item &&
                pod.item.length > 0 &&
                pod.item.map((item, idx) => <li key={idx}>{item}</li>)}
            </ul>
          </div>
        ))}
      </div>
      <div className={classes.snackDetailsWrapper}>
        <div className={classes.sideHeading}>AlpaaGo Snack Universe</div>
        <div className={classes.snackDetail}>
          <p className={classes.snackP}>
            <b>
              A universe of snacks to choose from! You read that right.&nbsp;
            </b>
          </p>
          <p className={classes.snackP}>
            We have created a huge database of snacks from vendors with high
            importance to TASTE and HYGIENE. We have created this to give you
            the best choice of menu almost every single day at affordable costs!
            Our algorithms use a unique method to identify what your community
            loves and selects snacks tailor-made for you to enjoy.&nbsp;
          </p>
          <p className={classes.snackP}>
            <b>AlpaaGo: CHOICE, TASTE, EASY and AFFORDABLE!&nbsp;</b>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Product;
