import { createBrowserRouter } from "react-router-dom";
import { routes } from "./routes";
import {
  GeneralLayout,
  AdminRootLayout,
  UserRootLayout,
  RootLayout,
} from "../Layout";
import { CampusInfo, CampusManagement } from "../pages/CampusManagement";
import { ManagePods, PodManagement } from "../pages/PodManagement";
import { ErrorAdminNavigationPage } from "../components";
import { CartingTool } from "../pages/CartingTool";
import { VendorInfo, VendorManagement } from "../pages/VendorManagement";
import { VendorProfile } from "../pages/VendorManagement"
import { UserInfo, UserManagement , UserProfile } from "../pages/UserManagement";
import { InventoryManagement } from "../pages/InventoryManagement";
import { VendorManageProducts } from "../pages/VendorManageProducts";
import {
  AdminLogin,
  CampusLogin,
  SignUp,
  UserLogin,
  VendorLogin,
} from "../pages/PreLogin";
import UserTransaction from "../pages/UserTransaction";
import { Wallet } from "../pages/Wallet";
import { checkAuthLoader } from "../common/common";
import Home from "../pages/Home";
import Vendor from "../pages/Vendor";
import Campus from "../pages/Campus";
import Admin from "../pages/Admin";
import AboutAlpaaGO from "../pages/AboutAlpaaGO";
import ContactUs from "../pages/ContactUs";
import VendorRootLayout from "../Layout/VendorRootLayout";
import CampusRootLayout from "../Layout/CampusRootLayout";
import { authSession } from "../assets/lib/login";
import { CampusPods } from "../pages/CampusPods";
import { VendorPods } from "../pages/VendorPods";
import VendorManageInventory from "../pages/VendorManageInventory";
import {TutorialsAdmin} from "../pages/TutorialsAdmin";
import {Dashboard} from "../pages/DashboardAdmin";



const createRoutes = createBrowserRouter([
  {
    path: "/",
    element: <RootLayout />,
    errorElement: <ErrorAdminNavigationPage />,
    children: [
      { path: routes.DEFAULT, element: <Home /> },
      { path: routes.ABOUT_PAGE, element: <AboutAlpaaGO /> },
      { path: routes.ADMIN_PAGE, element: <Admin /> },
      { path: routes.VENDOR_PAGE, element: <Vendor /> },
      { path: routes.CAMPUS_PAGE, element: <Campus /> },
      { path: routes.CONTACT_PAGE, element: <ContactUs /> },
      {
        path: routes.USERS,
        element: <UserRootLayout />,
        errorElement: <ErrorAdminNavigationPage />,
        children: [
          { path: routes.SIGN_UP, element: <SignUp /> },
          { path: routes.USER_LOGIN, element: <UserLogin /> },
          {
            path: routes.USER_TRANSACTION,
            element: <UserTransaction />,
            loader: () => checkAuthLoader(authSession.user),
          },
          {
            path: routes.WALLET,
            element: <Wallet />,
            loader: () => checkAuthLoader(authSession.user),
          },
          {
           path: routes.USER_PROFILE,
          //  path:`${routes.USER_PROFILE}/:userDocId`,
            element: <UserProfile />,
            loader: () => checkAuthLoader(authSession.user),
          },
        ],
      },
      {
        path: routes.VENDOR,
        element: <VendorRootLayout />,
        errorElement: <ErrorAdminNavigationPage />,
        children: [
          { path: routes.VENDOR_LOGIN, element: <VendorLogin /> },
          { path: routes.VENDOR_PROFILE, element: <VendorProfile /> },
          {
            path: routes.VENDOR_PODS,
            element: <VendorPods />,
            loader: () => checkAuthLoader(authSession.vendor),
          },
          {
            path: routes.VENDOR_MANAGE_INVENTORY,
            element: <VendorManageInventory />,
            loader: () => checkAuthLoader(authSession.vendor),
          },
          {
            path: routes.VENDOR_MANAGE_PRODUCTS,
            element: <VendorManageProducts/>,
            loader: () => checkAuthLoader(authSession.vendor),
          },
        ],
      },
      {
        path: routes.CAMPUS,
        element: <CampusRootLayout />,
        errorElement: <ErrorAdminNavigationPage />,
        children: [
          { path: routes.CAMPUS_LOGIN, element: <CampusLogin /> },
          {
            path: routes.CAMPUS_PODS,
            element: <CampusPods />,
            loader: () => checkAuthLoader(authSession.campus),
          },
        ],
      },
      {
        path: routes.ADMIN,
        element: <AdminRootLayout />,
        errorElement: <ErrorAdminNavigationPage />,
        children: [
          { path: routes.ADMIN_LOGIN, element: <AdminLogin /> },
          { path: routes.ADMIN_PAGE, element: <Admin /> },
          {
            path: routes.USER_MANAGEMENT,
            element: <GeneralLayout />,
            children: [
              { path: routes.DEFAULT, element: <UserManagement /> },
              { path: routes.USER_INFO, element: <UserInfo /> },
              { path: `${routes.USER_INFO}/:userDocId`, element: <UserInfo /> },
            ],
            loader: () => checkAuthLoader(authSession.admin),
          },
          {
            path: routes.CAMPUS_MANAGEMENT,
            element: <GeneralLayout />,
            children: [
              { path: routes.DEFAULT, element: <CampusManagement /> },
              { path: routes.CAMPUS_INFO, element: <CampusInfo /> },
              { path: `${routes.CAMPUS_INFO}/:campusDocId`, element: <CampusInfo /> },
            ],
            loader: () => checkAuthLoader(authSession.admin),
          },
          {
            path: routes.CARTING_TOOL,
            element: <CartingTool />,
            loader: () => checkAuthLoader(authSession.admin),
          },
          {
            path: routes.INVENTORY_MANAGEMENT,
            element: <GeneralLayout />,
            children: [
              { path: routes.DEFAULT, element: <InventoryManagement /> },
            ],
            loader: () => checkAuthLoader(authSession.admin),
          },
          {
            path: routes.POD_MANAGEMENT,
            element: <GeneralLayout />,
            children: [
              { path: routes.DEFAULT, element: <PodManagement /> },
              { path: `${routes.MANAGE_PODS}`, element: <ManagePods /> },
            ],
            loader: () => checkAuthLoader(authSession.admin),
          },
          {
            path: routes.VENDOR_MANAGEMENT,
            element: <GeneralLayout />,
            children: [
              { path: routes.DEFAULT, element: <VendorManagement /> },
              { path: routes.VENDOR_INFO, element: <VendorInfo /> },
              {
                path: `${routes.VENDOR_INFO}/:vendorDocId`,
                element: <VendorInfo />,
              },
            ],
            loader: () => checkAuthLoader(authSession.admin),
          },
          {
            path: routes.TUTORIALS_ADMIN,
            element: <TutorialsAdmin />,
            loader: () => checkAuthLoader(authSession.admin),
          },
          {
            path: routes.DASHBOARD,
            element: <Dashboard />,
            loader: () => checkAuthLoader(authSession.admin),
          },
        ],
      }
      
    ],
  },
]);

export default createRoutes;
