import { combineReducers } from "redux";
import * as UserDetails from "./user";
import * as PodDetails from "./pod";

const initialState = {
  user: UserDetails.initialState,
  pod: PodDetails.initialState,
};

const appReducer = combineReducers({
  user: UserDetails.updateUserState,
  pod: PodDetails.updatePodState,
});

export default function rootReducer(state, action) {
  let appState = state;
  // if(logout) {
  //     appState = initialState
  // }
  return appReducer(appState, action);
}
