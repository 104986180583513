const podHomeDetails = [
  {
    id: "goPodBasic",
    label: "GoPod Basic",
    item: [
      "UP TO 120 PRODUCTS OF 5 VARIETIES STORAGE CAPACITY.",
      "AL-PIN LOGIN METHOD",
      "AMBIENT ONLY",
      "WALL MOUNTED DESIGN",
      "FREE INSTALLATION",
    ],
  },
  {
    id: "goPodUltra",
    label: "GoPod Ultra",
    item: [
      "UP TO 360 PRODUCTS OF 5 VARIETIES OF STORAGE CAPACITY",
      "AL-PIN LOGIN METHOD",
      "HOT, AMBIENT AND COLD SPACE IN THE SAME SHELL",
      "FREE INSTALLATION",
    ],
  },
  {
    id: "goPodBooks",
    label: "GoPod Books",
    item: [
      "UPTO 120 A4 SIZED BOOKS OF STORAGE CAPACITY",
      "AL-PIN LOGIN METHOD",
      "ALL-WEATHER WOODEN SHELF DESIGN; WALL MOUNTED.",
      "FREE INSTALLATION",
    ],
  },
];

export { podHomeDetails };
