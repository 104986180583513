import React, { useState } from "react";
import { Button, makeStyles } from "@material-ui/core";
import { Menu, MenuItem, TextField } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { CustomTable } from "../../../components";
import { vendorManageInventory } from "../../../assets/lib/vendor";
import UpdateInventory from "./UpdateInventory";
import AddInventory from "./AddInventory";
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Box from "@mui/material/Box";

const customStyle = makeStyles({
  root: {
    padding: "20px 40px",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    gap: "30px",
    overflow: "hidden",
  },
});

const Layout = (props) => {
  const {
    podMenu,
    podSelected,
    setPodSelected,
    itemsData,
    isTableLoading,
    currentPage,
    totalLength,
    handlePageChange,
    handlePageSize,
    handleSearchQuery,
    handleTableSorting,
    updateModalClicked,
    inventoryState,
    setIsModalOpen,
    isModalOpen,
    handleSubmitModal,
    getInventoryInput,
  

    isModalOpenForProduct,
    setIsModalOpenForProduct,
    productState,
    handleSubmitModalforProducts,
    getProductInput,
    productMenu,
    setProductMenu,
    productData,
    handledelete

  } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const classes = customStyle();
  const actions = [
    {
      label: "Update",
      onClick: (row) => {
        updateModalClicked(row);
      },
      bgColor: "#2196F3",
    },
  ];

  const onChangeMenuItem = (item) => {
    if (item !== podSelected) {
      setPodSelected(item);
      setAnchorEl(null);
    }
  };

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] =useState(5);


  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return(
    <div className={classes.root}>
      <div>
        {!!podSelected && (
          <Button
            aria-controls="dropdown-menu"
            aria-haspopup="true"
            onClick={(event) => setAnchorEl(event.currentTarget)}
            variant="contained"
            color="primary"
            style={{ textTransform: "none", fontSize: "16px" }}
            endIcon={<KeyboardArrowDownIcon />}
          >
            {podSelected}
          </Button>
        )}
        <Menu
          id="dropdown-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={() => setAnchorEl(null)}
        >
          {podMenu.map((pod, index) => (
            <MenuItem onClick={() => onChangeMenuItem(pod)} key={index}>
              {pod}
            </MenuItem>
          ))}
        </Menu>
        <Button
            variant="contained"
            style={{
              color: "white",
              backgroundColor: "#FF5722",
              padding: "12px 24px",
              borderRadius: "10px",
              marginLeft:"755px"
            }}
            onClick={() => {
              setIsModalOpenForProduct(true);
            }}
          >
            Add Product
          </Button>
      </div>
    <Paper sx={{ width: '100%', overflow: 'hidden',marginLeft:"-12px",marginTop:"-20px"}} >
       <Box height={20} />
      <TableContainer sx={{ maxHeight: 350,marginTop:"-20px" }}>
        <Table stickyHeader aria-label="sticky table" >
         <TableHead >
         <TableRow >
            <TableCell align="center" style={{ minWidth: "100px", backgroundColor:"rgb(178, 205, 243)"}}>
             Product ID
             </TableCell>
             <TableCell align="center" style={{ minWidth: "100px", backgroundColor:"rgb(178, 205, 243)"}}>
             Product Name
             </TableCell>
             <TableCell align="center" style={{ minWidth: "100px", backgroundColor:"rgb(178, 205, 243)" }}>
             Price
             </TableCell>
             <TableCell align="center" style={{ minWidth: "100px", backgroundColor:"rgb(178, 205, 243)" }}>
             Quantity
             </TableCell>
             <TableCell align="center" style={{ minWidth: "100px", backgroundColor:"rgb(178, 205, 243)" }}>
             Expiry
             </TableCell>
             <TableCell align="center" style={{ minWidth: "100px", backgroundColor:"rgb(178, 205, 243)" }}>
              Action
             </TableCell>
         </TableRow>
       </TableHead>
        <TableBody>
        {itemsData

          .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
          .map((row) => {
             return (
               <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                <TableCell  align="center" >
                      {row.productId}
                     </TableCell>
                     <TableCell  align="center" >
                      {row.productName}
                     </TableCell>
                     <TableCell align="center" >
                      {row.unitPrice}
                     </TableCell>
                     <TableCell  align="center" >
                     {row.quantity}
                     </TableCell>
                     <TableCell align="center">
                    {row.expiry}
                     </TableCell>
                     <TableCell align="center">
                        {actions.map((action, index) => (
                          <Button
                            // className={classes.viewButton}
                            align="center"
                            variant="contained"
                            onClick={() => action.onClick(row)}
                            style={{ backgroundColor: action.bgColor }}
                            key={index}
                          >
                            {action.label}
                          </Button>
                        ))}
                      <Button
                      align="center"
                        variant="contained"
                        style={{
                          color: "white",
                          backgroundColor: "#FF5722",
                          borderRadius: "4px", 
                          marginLeft:"10px"
                        }}
                        onClick={() => {
                          handledelete(row);
                        }}
                      >
                        Delete
                      </Button>
                     </TableCell>
                  </TableRow>
                );
              })
              } 
          </TableBody>   
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5,10,20]}
        component="div"
        count={itemsData.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>

      <UpdateInventory
        isModalOpen={isModalOpen}
        updateModalClicked={updateModalClicked}
        inventoryState={inventoryState}
        setIsModalOpen={setIsModalOpen}
        handleSubmitModal={handleSubmitModal}
        getInventoryInput={getInventoryInput}

        productMenu={productMenu}
        setProductMenu={setProductMenu}
        productData={productData}

      />
      <AddInventory
        isModalOpenForProduct={isModalOpenForProduct}
        setIsModalOpenForProduct={setIsModalOpenForProduct}
        productState={productState}
        handleSubmitModalforProducts={handleSubmitModalforProducts}
        getProductInput={getProductInput}
        productMenu={productMenu}
        setProductMenu={setProductMenu}
        productData={productData}

      />
    </div>
    
  );
};

export default Layout;
