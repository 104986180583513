import React, { useRef } from "react";
import Layout from "./Layout";
import { useEffect } from "react";
import { useState } from "react";
import { COLLECTION, SESSION } from "../../../data/constant";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../../../config/firebase";
import axios from "axios";
import { createInitialForm } from "../../../common/common";
import { walletData } from "../../../assets/lib/wallet";
import { useNavigate } from "react-router-dom";

const Wallet = () => {
  const [walletAmount, setWalletAmount] = useState("");
  const [walletState, setWalletState] = useState(createInitialForm(walletData));
  const [rechargeLoader, setRechargeLoader] = useState(false);
  const userId = useRef("");
  const phoneNumber = useRef("");
  const navigate = useNavigate();

  const getWalletAmount = async () => {
    const sessionId = localStorage.getItem(SESSION.USER_SESSION_ID);
    const docRef = doc(db, COLLECTION.USER_SESSION, sessionId);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      const data = docSnap.data();
      userId.current = data.userID;
      phoneNumber.current = data.phoneNumber;
    }
    const userDocRef = doc(db, COLLECTION.USER, userId.current);
    const userDocSnap = await getDoc(userDocRef);
    if (userDocSnap.exists()) {
      const userData = userDocSnap.data();
      setWalletAmount(userData.walletAmount);
    }
  };

  const getWalletInput = (event) => {
    const name = event.target.name;
    let value = event.target.value;
    const inputType = event.target.type;
    if (value && inputType === "number") {
      value = parseInt(value);
    }
    setWalletState((prevState) => ({ ...prevState, [name]: value }));
  };

  useEffect(() => {
    getWalletAmount();
  }, []);

  const handle = () => {
    let bodyFormData = new FormData();
    bodyFormData.append("userId", userId.current);
    bodyFormData.append("amount", walletState.rechargeAmount);
    bodyFormData.append("mobileNumber", phoneNumber.current);
    bodyFormData.append(
      "redirectUrl",
      "https://asia-south1-alpaago-testbench.cloudfunctions.net/paymentverify"
    );
    bodyFormData.append(
      "callbackUrl",
      "https://asia-south1-alpaago-testbench.cloudfunctions.net/paymentverify"
    );
    setRechargeLoader(true);
    axios({
      method: "post",
      url: "https://asia-south1-alpaago-testbench.cloudfunctions.net/paymentpage",
      data: bodyFormData,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then(function (response) {
        //handle success
        const url = response.data.data.instrumentResponse.redirectInfo.url;
        setRechargeLoader(false);

        window.open(url, "_self");
      })
      .catch(function (response) {
        //handle error
        setRechargeLoader(false);
        console.log(response);
      });
  };

  return (
    <Layout
      walletAmount={walletAmount}
      handle={handle}
      getWalletInput={getWalletInput}
      walletState={walletState}
      rechargeLoader={rechargeLoader}
    />
  );
};

export default Wallet;
