import "./App.css";
import "../assets/global.css";
import { RouterProvider } from "react-router-dom";

import createRoutes from "../routing/createdBrowserRoutes";
import { ThemeProvider, createTheme } from "@mui/material/styles";

const THEME = createTheme({
  typography: {
    fontFamily: `"Open Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"`,
    fontSize: 16,
  },
});

const App = () => {
  return (
    <ThemeProvider theme={THEME}>
      <RouterProvider router={createRoutes}></RouterProvider>
    </ThemeProvider>
  );
};

export default App;
