const productData = {
  productId: {
      key: "productId",
      label: "Product Id",
      placeHolder: "Enter Product Id",
      type: "text",
    },
    productName: {
      key: "productName",
      label: "Product Name",
      placeHolder: "Enter Product Name",
      type: "text",
      isRequired: true,
    },
    unitPrice: {
      key: "unitPrice",
      label: "Unit Price",
      placeHolder: "Enter Unit Price",
      type: "number",
      isRequired: true,
    },
    expiry: {
      key: "expiry",
      label: "Expiry",
      placeHolder: "Enter expiry in days",
      type: "number",
      
    },
  };
  
  const productTableHeading = [
    {
      key: "productId",
      label: "Product Id",
    },
    {
      key: "productName",
      label: "Product Name",
    },
    {
      key: "unitPrice",
      label: "Unit Price",
    },
    {
      key: "expiry",
      label: "Expiry",
    },
  ];
  

  
  export { productData, productTableHeading };
  
  