import React from "react";
import { makeStyles } from "@material-ui/core";

const customStyles = makeStyles({
  root: {
    padding: "100px 0px",
    width: "90%",
    maxWidth: "1216px",
    display: "flex",
    flexDirection: "column",
    marginRight: "auto",
    marginLeft: "auto",
    fontFamily:"Manrope"
  },
  header: {
    margin: "26px 0px",
    color: "#271340",
    maxWidth: "50%",
    fontSize: "64px",
    fontWeight: 600,
    lineHeight: 1.1,
    fontFamily:"Manrope"

  },
});

const AboutAlpaaGO = () => {
  const classes = customStyles();
  return (
    <div className={classes.root}>
      <span className="font-size-18  purple-text">
        <span className="grey-text">Home &gt; </span>
        <span>About AlpaaGO</span>
      </span>
      <h1 className={classes.header}>About AlpaaGO</h1>
    </div>
  );
};

export default AboutAlpaaGO;
