import React, { useEffect, useState } from "react";
import { Button, makeStyles, Grid } from "@material-ui/core";
import { Menu, MenuItem, TextField } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

import { podData } from "../../../assets/lib/pod";
import {
  CustomModal,
  SingleInputField,
  TwoInputField,
} from "../../../components";

const customStyle = makeStyles({
  formContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "20px",
  },
  formGroup: {
    display: "flex",
    flexDirection: "column",
    gap: "10px",
  },
  formLabel: {
    fontSize: "16px",
    fontWeight: "bold",
  },
});

const PodModal = (props) => {
  const {
    setIsModalOpen,
    isModalOpen,
    handleSubmitModal,
    podState,
    getPodInput,
    errorFields,
    loadingModal,
    campusData,
    campusMenu,
    setCampusMenu,
    vendorData,
    vendorMenu,
    setVendorMenu,
  } = props;
  const classes = customStyle();
  const [anchorElCampus, setAnchorElCampus] = useState(null);
  const [anchorElVendor, setAnchorElVendor] = useState(null);

  const onChangeMenuItemCampus = (item) => {
    setCampusMenu(item);
    setAnchorElCampus(null);
  };

  const onChangeMenuItemVendor = (item) => {
    setVendorMenu(item);
    setAnchorElVendor(null);
  };

  useEffect(() => {
    if (campusData.length > 0) {
      setCampusMenu(campusData[0]);
    }
  }, [campusData]);

  useEffect(() => {
    if (vendorData.length > 0) {
      setVendorMenu(vendorData[0]);
    }
  }, [vendorData]);

  return (
    <CustomModal
      heading={"Add Pod"}
      open={isModalOpen}
      onClose={() => setIsModalOpen(false)}
      onSubmit={handleSubmitModal}
      showLoading={loadingModal}
    >
      <div className={classes.formContainer}>
        <TwoInputField
          content={podData.podNumber}
          value={podState.podNumber}
          isEditable={false}
          secondContent={podData.podType}
          secondValue={podState.podType}
          errorFields={errorFields}
          getInputData={getPodInput}
        />
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <SingleInputField
              content={podData.manufacture}
              value={podState.manufacture}
              getInputData={getPodInput}
              errorFields={errorFields}
            />
          </Grid>
          <Grid item xs={6}>
            <div className={classes.formGroup}>
              <span className={classes.formLabel}>Campus :</span>
              <Button
                aria-controls="dropdown-menu"
                aria-haspopup="true"
                onClick={(event) => setAnchorElCampus(event.currentTarget)}
                variant="contained"
                color="primary"
                style={{ textTransform: "none", fontSize: "16px" }}
                endIcon={<KeyboardArrowDownIcon />}
              >
                {campusMenu?.campusName}
              </Button>
              <Menu
                id="dropdown-menu"
                anchorEl={anchorElCampus}
                keepMounted
                open={Boolean(anchorElCampus)}
                onClose={() => setAnchorElCampus(null)}
              >
                {campusData.map((campus, index) => (
                  <MenuItem
                    onClick={() => onChangeMenuItemCampus(campus)}
                    key={index}
                  >
                    {campus?.campusName}
                  </MenuItem>
                ))}
              </Menu>
            </div>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <div className={classes.formGroup}>
              <span className={classes.formLabel}>Vendor :</span>
              <Button
                aria-controls="dropdown-menu"
                aria-haspopup="true"
                onClick={(event) => setAnchorElVendor(event.currentTarget)}
                variant="contained"
                color="primary"
                style={{ textTransform: "none", fontSize: "16px" }}
                endIcon={<KeyboardArrowDownIcon />}
              >
                {vendorMenu?.vendorName}
              </Button>
              <Menu
                id="dropdown-menu"
                anchorEl={anchorElVendor}
                keepMounted
                open={Boolean(anchorElVendor)}
                onClose={() => setAnchorElVendor(null)}
              >
                {vendorData.map((vendor, index) => (
                  <MenuItem
                    onClick={() => onChangeMenuItemVendor(vendor)}
                    key={index}
                  >
                    {vendor?.vendorName}
                  </MenuItem>
                ))}
              </Menu>
            </div>
          </Grid>
        </Grid>
      </div>
    </CustomModal>
  );
};

export default PodModal;
