import React from "react";
import { makeStyles,Button } from "@material-ui/core";
import { useState } from 'react';
import './Dashboard.css';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const customStyle =  makeStyles({
  root: {
    padding: "60px 40px",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    gap: "30px",
  },
    formInput: {
      padding: "16px",
      border: "1px solid #ccc",
      borderRadius: "6px",
      fontSize: "18px",
      width: "250px",
      marginLeft:"25px"
    },
     // Media query for iPhone 13 and smaller screens
  '@media (max-width: 390px)': {
    root: {
      padding: "50px 0px", 
      gap: "15px", 
    },
    formInput: {
      padding: "10px", 
      fontSize: "16px", 
      width: "90%", 
      marginLeft: "0", 
      marginRight: "auto", 
    },
  },
});


const Layout = (props) => {
  const {
    userscount,
    totaltransaction,
    todaytransaction,
    walletamount,
    alpinperday
  } = props;
  const classes = customStyle();
  const rupeeSymbol = '₹';

      // Extract keys and values from the documentCounts object
      const labels = Object.keys(alpinperday);
      const dataValues = Object.values(alpinperday);
  
      const getColor = (index) => {
        if (index === 0) return 'red'; // Initial color
        return dataValues[index] > dataValues[index - 1] 
            ? 'green'  // Rising color
            : 'red'; // Falling color
    };
    
      // Prepare chart data
      const data = {
          labels: labels, // X-axis labels
          datasets: [
              {
                  label: 'Alpin Counts',
                  data: dataValues, // Y-axis values
                  backgroundColor: dataValues.map((_, index) => getColor(index)), // Set color based on trend
                  borderColor: '#4285F4',
                  borderWidth: 1,
                  fill: true, // Fill under the line
              },
          ],
      };
  
      // Chart options
      const options = {
          scales: {
              y: {
                  beginAtZero: true, // Start y-axis at 0
              },
          },
      }

     

  return (
    <div className={classes.root} style={{marginTop:"-30px"}}>

    <div className="card-container">
     
    <div className="color-card">
      <h3 className="card-title">Total Alpins</h3>
      <h2 className="card-content">{userscount}</h2>
    </div>

    <div className="color-card">
      <h3 className="card-title">Wallet Amount</h3>
      <h2 className="card-content">{rupeeSymbol}{walletamount}</h2>
    </div>

    <div className="color-card">
      <h3 className="card-title">Total Transaction</h3>
      <h2 className="card-content">{rupeeSymbol}{totaltransaction}</h2>
    </div>

    <div className="color-card">
      <h3 className="card-title">Today's Transaction</h3>
      <h2 className="card-content">{rupeeSymbol}{todaytransaction}</h2>
    </div>

    </div>

    <div className="graph-container">
   <Line data={data} options={options} />
    </div>
     
    </div>
  );
};

export default Layout;
