import React, { useEffect, useRef, useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import { makeStyles, Button } from "@material-ui/core";
import { Menu, MenuItem, TextField } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { CustomModal } from "../../../components";
import { useMediaQuery } from '@mui/material';

const customStyle = makeStyles({
  camera: {
    display: "flex",
    flexDirection: "column",
  },
  cameraContainer: {
    gap: "10px",
    display: "flex",
    minWidth: "650px",
  },
  addedItemContainer: {
    gap: "10px",
    margin: "15px 0px",
    display: "flex",
    flexDirection: "column",
    maxHeight: "115px",
    overflowY: "scroll",
    overflowX: "hidden",
  },
  formInput: {
    padding: "16px",
    border: "1px solid #ccc",
    borderRadius: "4px",
    fontSize: "18px",
  },
  '@media (max-width: 390px)': {
    camera: {
      display: "flex",
      flexDirection: "column",
    },
    cameraContainer: {
      gap: "5px",
      display: "flex",
      minWidth: "250px",
    },
    addedItemContainer: {
      gap: "5px",
      margin: "5px 0px",
      display: "flex",
      flexDirection: "column",
      maxHeight: "115px",
      overflowY: "scroll",
      overflowX: "hidden",
    },
    formInput: {
      padding: "16px",
      border: "1px solid #ccc",
      borderRadius: "4px",
      fontSize: "18px",
    },
  },
});

const UpdateModal = (props) => {
  const {
    isModalOpen,
    isLoading,
    onClose,
    currentImages,
    menuItems,
    addedItems,
    setAddedItems,
    handleAddItem,
    handleDeleteItem,
    onChangeValue,
    handleSubmit,
    validationErrors,
    submitLoading,
    images,
    currentImagesfirst,
    handleNothing
  } = props;
  const classes = customStyle();
  const [anchorEls, setAnchorEls] = useState([]);
  const [one, setOne] = useState([]);
  const isPhone = useMediaQuery('(max-width: 390px)');

  // Initialize anchorEls when menuItems change
  useEffect(() => {
    setAnchorEls(Array.from({ length: addedItems.length }, () => null));
  }, [menuItems, addedItems.length]);

  const handleMenuOpen = (event, index) => {
    const newAnchorEls = Array.isArray(anchorEls) ? [...anchorEls] : [];
    newAnchorEls[index] = event.currentTarget;
    setAnchorEls(newAnchorEls);
  };

  const handleMenuClose = (index) => {
    const newAnchorEls = [...anchorEls];
    newAnchorEls[index] = null;
    setAnchorEls(newAnchorEls);
  };

  const onChangeMenuItem = (menuItem, id) => {
    setAddedItems((prevItems) => {
      const itemToUpdate = prevItems.find((item) => item.id === id);
      if (itemToUpdate) {
        itemToUpdate.selectedItem = menuItem;
      }
      return prevItems;
    });

    setAnchorEls(null);
  };

  const isValidInput = (id) =>
    validationErrors.some((error) => error.id === id);

  return (
    <CustomModal
      heading={"Update Transaction"}
      open={isModalOpen}
      onClose={onClose}
      width={isLoading ? "650px" : "auto"}
      isFetching={isLoading}
      hideSubmitButton={
        currentImages === null || Object.keys(currentImages).length === 0
      }
      onSubmit={handleSubmit}
      showLoading={submitLoading}
    >
      <div className="flex-direction-column" >
          <div className={classes.cameraContainer}
          style={{
            height: isPhone ? "130px" : "250px", 
            marginTop: isPhone ? "-15px" : "-25px" 
          }}>
              {currentImagesfirst && Object.keys(currentImagesfirst)
                ? Object.keys(currentImagesfirst).map((cameraNum) => (

                      <div className={classes.camera} key={cameraNum}  >
                      <img
                      style={{
                        marginTop: isPhone ? "0px" : "0px", 
                        height: isPhone ? "80px" : "170px", 
                        width: isPhone ? "105px" : "350px", 
                      }}
                        key={cameraNum}
                        src={currentImagesfirst[cameraNum].imageUrl}
                        alt={`Image ${cameraNum}`}
                      />
                    

                    <b> <span style={{marginLeft:isPhone ? "5px":"0px" ,fontSize: isPhone ? "4.5px" : "16px",}}>Camera : {(currentImagesfirst[cameraNum].imageName)}</span></b>
                    </div>        
                              
                  ))
                : "No data"
                }
            </div>

        <div className={classes.cameraContainer} style={{height:"100px"}}>
    {currentImages && Object.keys(currentImages)
      ? Object.keys(currentImages).map((cameraNum) => (

            <div className={classes.camera} key={cameraNum}  >
            <img
            style={{
              marginTop: isPhone ? "-20px" : "-30px", 
              height: isPhone ? "80px" : "170px", 
              width: isPhone ? "105" : "350px", 
            }}
              key={cameraNum}
              src={currentImages[cameraNum].imageUrl}
              alt={`Image ${cameraNum}`}
            />
           

           <b> <span style={{marginLeft:isPhone ? "5px":"0px" ,fontSize: isPhone ? "4.5px" : "16px",}}>Camera : {(currentImages[cameraNum].imageName)}</span></b>
          </div>        
                     
        ))
      : "No data"
      }
  </div>

        <div className={classes.addedItemContainer} style={{marginTop: isPhone ? "-60px" : "20px"}}>
          {addedItems.map((item, index) => (
            <div className="flex gap-10 align-items-center" key={item.id} style={{marginLeft:"170px",marginTop:"50px"}}>
              <Button
                aria-controls={`dropdown-menu-${index}`}
                aria-haspopup="true"
                onClick={(event) => handleMenuOpen(event, index)}
                variant="contained"
                color="primary"
                style={{
                  textTransform: "none",
                  fontSize: isPhone ? "8px" : "16px",
                  height: "54px",
                  marginLeft: isPhone ? "-45px" : "0px", 
                }}
                endIcon={<KeyboardArrowDownIcon />}
              >
                {item.selectedItem?.productName}
              </Button>
              <Menu
                id={`dropdown-menu-${index}`}
                anchorEl={
                  anchorEls && anchorEls.length > 0 ? anchorEls[index] : null
                }
                keepMounted
                open={Boolean(
                  anchorEls && anchorEls.length > 0 && anchorEls[index]
                )}
                onClose={() => handleMenuClose(index)}
              >
                {menuItems.map((menuItem, menuItemIndex) => {
                  return (
                    <MenuItem
                      key={menuItemIndex}
                      onClick={() => onChangeMenuItem(menuItem, item.id)}
                    >
                      {menuItem.productName}
                    </MenuItem>
                  );
                })}
              </Menu>
              <input
                className={`${classes.formInput} ${
                  isValidInput(item.id) ? "error-input-color" : ""
                }`}
                type={"number"}
                placeholder={"Enter Number of Quantities"}
                value={item.quantity}
                onChange={(e) => onChangeValue(e.target.value, item.id)}
              />
              {isValidInput(item.id) && (
                <span className="font-size-12">Must be greater than 0</span>
              )}
              {index > 0 && (
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => handleDeleteItem(item.id)}
                  style={{
                    textTransform: "none",
                    fontSize: isPhone ? "8px" : "16px", // Adjust font size based on device
                    height: "54px",
                    marginLeft: isPhone ? "-225px" : "0px", // Adjust marginLeft for mobile
                  }}
                >
                  Delete
                </Button>
              )}
            </div>
          ))}
        </div>

        <Button
          variant="contained"
          style={{
            color: "white",
            backgroundColor: "#FF5722",
            padding: isPhone ? "8px 16px" : "12px 24px", 
            borderRadius: "10px",
            width: isPhone ? "120px" : "150px", 
            marginTop: isPhone ? "-50px" : "-65px",
             
          }}
          onClick={handleAddItem}
        >
          + Add Item
        </Button>
      </div>
      <Button
                  variant="contained"
                  style={{
                    backgroundColor: "#f50057",
                    color: "white",
                    marginLeft: isPhone ? "160px" : "170px", 
                    marginBottom: isPhone ? "-95px" : "-110px", 
                    marginTop: isPhone ? "-20px" : "-28px", 
                    height:  isPhone ? "40px" :"45px",
                  }}
                  onClick={handleNothing}
                >
                  Nothing Bought
                </Button>
    </CustomModal>
  );
};

export default UpdateModal;
