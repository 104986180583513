import React from "react";
import { makeStyles } from "@material-ui/core";

import { podMachineData } from "../../../assets/lib/pod";
import { CustomModal, TwoInputField } from "../../../components";

const customStyle = makeStyles({
  formContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "20px",
  },
});

const PodTypeModal = (props) => {
  const {
    setIsModalOpen,
    isModalOpen,
    handleSubmitModal,
    podMachineState,
    getPodMachineInput,
    errorFields,
    loadingModal,
  } = props;
  const classes = customStyle();

  return (
    <CustomModal
      heading={"Add Pod"}
      open={isModalOpen}
      onClose={() => setIsModalOpen(false)}
      onSubmit={handleSubmitModal}
      showLoading={loadingModal}
    >
      <div className={classes.formContainer}>
        <TwoInputField
          content={podMachineData.podType}
          value={podMachineState.podType}
          isEditable={false}
          secondContent={podMachineData.podName}
          secondValue={podMachineState.podName}
          errorFields={errorFields}
          getInputData={getPodMachineInput}
        />
        <TwoInputField
          content={podMachineData.noOfTrays}
          value={podMachineState.noOfTrays}
          secondContent={podMachineData.footfall}
          secondValue={podMachineState.footfall}
          errorFields={errorFields}
          getInputData={getPodMachineInput}
        />
      </div>
    </CustomModal>
  );
};

export default PodTypeModal;
