import { routes } from "../routing/routes";

export const RootNavTabs = [
  {
    id: "home",
    label: "Home",
    tabUrl: routes.HOME_PAGE,
  },
  {
    id: "admin",
    label: "Admin",
    tabUrl: routes.ADMIN_PAGE,
  },
  {
    id: "vendor",
    label: "Vendors",
    tabUrl: routes.VENDOR_PAGE,
  },
  {
    id: "campus",
    label: "Campus",
    tabUrl: routes.CAMPUS_PAGE,
  },
  {
    id: "about",
    label: "About AlpaaGO",
    tabUrl: routes.ABOUT_PAGE,
  },
  {
    id: "contact",
    label: "Contact Us",
    tabUrl: routes.CONTACT_PAGE,
  },
];
