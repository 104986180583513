import React from "react";
import { CustomTable } from "../../../components";
import { userTransactionTableHeading } from "../../../assets/lib/userTransaction";
import { makeStyles } from "@material-ui/core";

const customStyle = makeStyles({
  root: {
    padding: "60px 40px",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    gap: "30px",
  },
});

const Layout = (props) => {
  const {
    userTransactionData,
    isTableLoading,
    currentPage,
    totalLength,
    handlePageChange,
    handlePageSize,
    handleSearchQuery,
    handleTableSorting,
  } = props;
  const classes = customStyle();

  return (
    <div className={classes.root}>
      <CustomTable
        columns={userTransactionTableHeading}
        data={userTransactionData}
        isLoading={isTableLoading}
        currentPage={currentPage}
        totalLength={totalLength}
        handlePageChange={handlePageChange}
        handlePageSize={handlePageSize}
        handleTableSorting={handleTableSorting}
        // renderCell={(row, column) => {
        //   // Custom rendering logic for payment history column
        //   if (column.id === "paymentHistory") {
        //     // Assuming payment history is stored as an array in the transaction data
        //     return row.paymentHistory.join(", ");
        //   }
        //   // Render other columns normally
        //   return row[column.id];
        // }}
      />
    </div>
  );
};

export default Layout;
