import React from "react";
import { makeStyles } from "@material-ui/core";
import Button from "@mui/material/Button";
import SignupBackground from "../../../assets/Images/signupBackground.jpg";
import TextAnimation from "../../../assets/Images/textAnimation.png";
import { SingleInputField } from "../../../components";
import { loginData } from "../../../assets/lib/login";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "@mui/material";

const customStyle = makeStyles({
  leftContent: {
    // background: "rgb(74 0 255 / 64%)",
    background: `url(${SignupBackground}) top left repeat`,
    backgroundSize: "cover",
    top: "0",
    bottom: "0",
    minHeight: "100vh", // Change to minHeight: "100vh",
    textAlign: "right",
    opacity: "1",
    position: "relative",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "30px 50px",
  },
  rightContent: {
    padding: "40px",
  },
  colorBg: {
    position: "absolute",
    content: "''",
    top: "0",
    left: "0",
    right: "0",
    bottom: "0",
    background: "rgb(74 0 255 / 64%)",
  },
  animatedText: {
    display: "block",
    textShadow: "0 0 80px rgba(255, 255, 255, 0.5)",
    background: `url(${TextAnimation}) repeat-y`,
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    WebkitAnimation: "aitf 80s linear infinite",
    WebkitTransform: "translate3d(0, 0, 0)",
    WebkitBackfaceVisibility: "hidden",
    fontSize: "50px",
    margin: "0px",
  },
  information: {
    display: "flex",
    height: "100%",
    width: "100%",
    flexDirection: "column",
    justifyContent: "center",
    justifyContent: "center",
    gap: "20px",
    color: "white",
    zIndex: "2",
  },
  inputContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "10px",
  },
  // Media Query for iPhone 13 Pro
  '@media (max-width: 390px)': {
    leftContent: {
      minHeight: "25vh", // Change to minHeight: "100vh",
      textAlign: "left",
      opacity: "1",
      position: "relative",
      display: "flex",
      justifyContent: "center",
      alignItems: "start",
      padding: "10px 10px",
    },
    rightContent: {
      background: "rgb(74 0 255 / 64%)",
      background: `url(${SignupBackground}) top left repeat`,
      backgroundSize: "cover",
      minHeight: "25vh", // Change to minHeight: "100vh",
      textAlign: "left",
      opacity: "1",
      position: "relative",
      padding: "25px 35px",
      color: "white",

    },
    colorBg: {
      position: "absolute",
      content: "''",
      top: "0",
      left: "0",
      right: "0",
      bottom: "0",
      background: "rgb(74 0 255 / 64%)",
    },
    animatedText: {
      display: "block",
      textShadow: "0 0 80px rgba(255, 255, 255, 0.5)",
      background: `url(${TextAnimation}) repeat-y`,
      WebkitBackgroundClip: "text",
      WebkitTextFillColor: "transparent",
      WebkitAnimation: "aitf 80s linear infinite",
      WebkitTransform: "translate3d(0, 0, 0)",
      WebkitBackfaceVisibility: "hidden",
      fontSize: "30px",
      margin: "0px",
    },
    information: {
      display: "flex",
      width: "100%",
      flexDirection: "column",
      justifyContent: "center",
      justifyContent: "center",
      gap: "20px",
      color: "white",
      zIndex: "2",
    },
    inputContainer: {
      display: "flex",
      flexDirection: "column",
      gap: "5px",
      color: "white",      
     },
  },
});

const Layout = (props) => {
  const {
    loginState,
    errorFields,
    getLoginInput,
    handleLogin,
    loginFailedText,
  } = props;
  const classes = customStyle();
  const navigate = useNavigate();
  const isMobile = useMediaQuery('(max-width: 390px)');

  return (
    <div className="width-100-per flex" style={{ display: 'flex', flexDirection: isMobile ? 'column-reverse' : 'row' }}>
      <div className="width-50-per" style={{  width: isMobile? '100%': "50%"}}>
        <div className={classes.leftContent}>
          <div className={classes.colorBg} />
          <div className={classes.information}>
            <h1 className={classes.animatedText}>Alpaago Snack Universe</h1>
            <span>
              We have created a huge database of snacks from vendors with high
              importance to TASTE and HYGIENE. We have created this to give you
              the best choice of menu almost every single day at affordable
              costs! Our algorithms use a unique method to identify what your
              community loves and selects snacks tailor-made for you to enjoy.
            </span>
          </div>
        </div>
      </div>
      <div className="width-50-per" style={{  width: isMobile? '100%': "50%"}}>
        <div className={classes.rightContent}>
          <div className="details">
            <h1 className="yellow-text mr-0">Welcome!</h1>
            <h2>Sign Into Your Account</h2>

            {!!loginFailedText && <p>{loginFailedText}</p>}
    
            <div className={classes.inputContainer}>
              <SingleInputField
                content={loginData.phoneNumber}
                value={loginState.phoneNumber}
                getInputData={getLoginInput}
                errorFields={errorFields}
              />
              <SingleInputField
                content={loginData.alpin}
                value={loginState.alpin}
                getInputData={getLoginInput}
                errorFields={errorFields}
              />
            </div>

            <div className="mr-tp-20">
              <Button
                variant="contained"
                sx={{ bgcolor: "#00C853", width: "120px" }}
                onClick={handleLogin}
              >
                Login
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Layout;
