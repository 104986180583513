import React from "react";
import Layout from "./Layout";
import { useState } from "react";
import useTableFunction from "../../../hooks/useTableFunction";
import { COLLECTION, COLLECTION_COUNTER } from "../../../data/constant";
import { podData } from "../../../assets/lib/pod";
import {
  createInitialForm,
  fetchLatestId,
  updateLatestId,
  validateRequiredField,
} from "../../../common/common";
import {
  addDoc,
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  updateDoc,
  setDoc
} from "firebase/firestore";
import { db } from "../../../config/firebase";
import { useEffect } from "react";

const PodManagement = () => {
  const [podState, setPodState] = useState(createInitialForm(podData));
  const [campusData, setCampusData] = useState([]);
  const [vendorData, setVendorData] = useState([]);
  const [errorFields, setErrorFields] = useState([]);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [isFetchingPodNum, setIsFetchingPodNum] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [errorOnFetch, setErrorOnFetch] = useState(false);
  const [campusMenu, setCampusMenu] = useState(null);
  const [vendorMenu, setVendorMenu] = useState(null);

  useEffect(() => {
    const fetchCampusVendorData = async () => {
      const collectionCampusRef = collection(db, COLLECTION.CAMPUS);
      const baseCampusQuery = query(collectionCampusRef);

      const dataCampusSnapshot = await getDocs(baseCampusQuery);

      const collectionCampusData = dataCampusSnapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));

      setCampusData(collectionCampusData);

      const collectionVendorRef = collection(db, COLLECTION.VENDOR);
      const baseVendorQuery = query(collectionVendorRef);

      const dataVendorSnapshot = await getDocs(baseVendorQuery);

      const collectionVendorData = dataVendorSnapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));

      setVendorData(collectionVendorData);
    };
    fetchCampusVendorData();
  }, []);

  const getPodInput = (event) => {
    const name = event.target.name;
    let value = event.target.value;

    const inputType = event.target.type;
    if (value && inputType === "number") {
      value = parseInt(value);
    }
    if (errorFields.includes(name) && ![undefined, null, ""].includes(value)) {
      setErrorFields((prevState) => prevState.filter((key) => key !== name));
    }
    setPodState((prevState) => ({ ...prevState, [name]: value }));
  };

  const updateCampusPod = async () => {
    const campusRef = doc(db, COLLECTION.CAMPUS, campusMenu.id);
    const campusDocSnap = await getDoc(campusRef);
    if (campusDocSnap.exists()) {
      const campusData = campusDocSnap.data();

      if (campusData?.podsInCampus && campusData.podsInCampus.length > 0) {
        await updateDoc(campusRef, {
          podsInCampus: [...campusData.podsInCampus, podState.podNumber],
        });
      } else {
        await updateDoc(campusRef, {
          podsInCampus: [podState.podNumber],
        });
      }
    }
  };

  const updateVendorPod = async () => {
    const vendorRef = doc(db, COLLECTION.VENDOR, vendorMenu.id);

    const vendorDocSnap = await getDoc(vendorRef);
    if (vendorDocSnap.exists()) {
      const vendorData = vendorDocSnap.data();

      if (vendorData?.podsForVendor && vendorData.podsForVendor.length > 0) {
        await updateDoc(vendorRef, {
          podsForVendor: [...vendorData.podsForVendor, podState.podNumber],
        });
      } else {
        await updateDoc(vendorRef, {
          podsForVendor: [podState.podNumber],
        });
      }
    }
  };
console.log("pod state",podState)
  const handleSubmitModal = async () => {
    const error = validateRequiredField(podData, podState);
    setErrorFields(error);
    setSubmitLoading(true);
    if (error.length === 0) {
      try {
        // Add new data
        const podCollectionRef = collection(db, COLLECTION.POD);
        await addDoc(podCollectionRef, {
          ...podState,
          campus: campusMenu.campusId,
          vendor: vendorMenu.vendorId,
        });
        await updateLatestId(COLLECTION_COUNTER.POD, podState.podNumber);
        await updateCampusPod();
        await updateVendorPod();
        
        // Add new data to Inventory
        const inventoryCollectionRef = collection(db, COLLECTION.INVENTORY);
        await setDoc(doc(inventoryCollectionRef, podState.podNumber), {})

        setIsModalOpen(false);
        refetchTableData();
        setPodState(createInitialForm(podData));
      } catch (error) {
        console.log("error", error);
        setErrorOnFetch(true);
      }
    }
    setSubmitLoading(false);
  };

  const fetchPodNumber = async () => {
    setIsFetchingPodNum(true);
    const latestId = await fetchLatestId(COLLECTION_COUNTER.POD);
    setPodState((prevState) => ({
      ...prevState,
      [podData.podNumber.key]: latestId,
    }));
    setIsFetchingPodNum(false);
  };

  const {
    tableData,
    isTableLoading,
    currentPage,
    totalLength,
    handlePageChange,
    handlePageSize,
    handleSearchQuery,
    handleTableSorting,
    refetchTableData,
  } = useTableFunction({
    collectionPath: COLLECTION.POD,
    defaultOrderBy: podData.podNumber.key,
  });

  return (
    <Layout
      podTableData={tableData}
      isTableLoading={isTableLoading}
      currentPage={currentPage}
      totalLength={totalLength}
      handlePageChange={handlePageChange}
      handlePageSize={handlePageSize}
      handleSearchQuery={handleSearchQuery}
      handleTableSorting={handleTableSorting}
      isModalOpen={isModalOpen}
      setIsModalOpen={setIsModalOpen}
      handleSubmitModal={handleSubmitModal}
      podState={podState}
      getPodInput={getPodInput}
      errorFields={errorFields}
      fetchPodNumber={fetchPodNumber}
      loadingModal={isFetchingPodNum || submitLoading}
      campusData={campusData}
      campusMenu={campusMenu}
      setCampusMenu={setCampusMenu}
      vendorData={vendorData}
      vendorMenu={vendorMenu}
      setVendorMenu={setVendorMenu}
    />
  );
};

export default PodManagement;
