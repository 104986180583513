import React from "react";
import Layout from "./Layout";
import { useDispatch, useSelector } from "react-redux";
import { db } from "../../../config/firebase";
import { collection } from "firebase/firestore";
import { useRef } from "react";
import useTableFunction from "../../../hooks/useTableFunction";
import { COLLECTION } from "../../../data/constant";
import { userTableHeading } from "../../../assets/lib/user";

export default function UserManagement() {
  const {
    tableData,
    isTableLoading,
    currentPage,
    totalLength,
    handlePageChange,
    handlePageSize,
    handleSearchQuery,
    handleTableSorting,
  } = useTableFunction({
    collectionPath: COLLECTION.USER,
    // defaultOrderBy: campusData.campusId.key,
  });

  return (
    <>
      <Layout
        userTableData={tableData}
        isTableLoading={isTableLoading}
        currentPage={currentPage}
        totalLength={totalLength}
        handlePageChange={handlePageChange}
        handlePageSize={handlePageSize}
        handleSearchQuery={handleSearchQuery}
        handleTableSorting={handleTableSorting}
      />
    </>
  );
}
