import moment from "moment";
import { checkValidMoment } from "../../common/common";


const userTransactionTableHeading = [
  {
    key: "phoneNumber",
    label: "Phone Number",
  },
  {
    key: "transMessage",
    label: "Transaction Message",
  },
  {
    key: "transactionTime",
    label: "Transaction Time",
    format: (value) => checkValidMoment(value?.toDate(), "YYYY-MM-DD hh:mm A"),
  },
  {
    key: "paymentHistory",
    label: "Payment History",
    format: (value) => `₹${value}`
  },
];

export { userTransactionTableHeading };
