const campusData = {
  campusId: {
    key: "campusId",
    label: "Campus ID",
    placeHolder: "Enter Campus Id",
    type: "text",
  },
  campusName: {
    key: "campusName",
    label: "Campus Name",
    placeHolder: "Enter Campus name",
    type: "text",
    isRequired: true,
  },
  GSTNumber: {
    key: "GSTNumber",
    label: "GST Number",
    placeHolder: "Enter GST Number",
    type: "text",
    isRequired: true,
  },
  phoneNumber: {
    key: "phoneNumber",
    label: "Phone Number",
    placeHolder: "Enter Phone number",
    type: "number",
    isRequired: true,
  },
  campusAddress: {
    key: "campusAddress",
    label: "Address",
    placeHolder: "Enter Campus address",
    type: "text",
  },
  location: {
    key: "location",
    label: "Location",
    placeHolder: "Enter Google Map Location",
    type: "text",
  },
  zone: {
    key: "zone",
    label: "Zone",
    placeHolder: "Enter Zone",
    type: "text",
  },
  rent: {
    key: "rent",
    label: "Rent",
    placeHolder: "Enter Rent",
    type: "number",
  },
  noOfPods: {
    key: "noOfPods",
    label: "Number Of Pods",
    placeHolder: "Enter Number Of Pods",
    type: "number",
    defaultValue: 0,
  },
  commission: {
    key: "commission",
    label: "Commission %",
    placeHolder: "Enter Commission percentage",
    type: "number",
  },
  contactPersonName: {
    key: "contactPersonName",
    label: "Name",
    placeHolder: "Enter name",
    type: "text",
  },
  contactPersonPhone: {
    key: "contactPersonPhone",
    label: "Phone Number",
    placeHolder: "Enter Phone Number",
    type: "number",
  },
};

const campusTableHeading = [
  {
    ...campusData.campusId,
    sorting: true,
    styleHeader: {
      minWidth: "105px",
    },
  },
  {
    ...campusData.campusName,
    styleHeader: {
      minWidth: "107px",
    },
  },
  { ...campusData.location },
  { ...campusData.zone },
  { ...campusData.campusAddress },
  {
    ...campusData.noOfPods,
    sorting: true,
    styleHeader: {
      minWidth: "148px",
    },
  },
  {
    ...campusData.rent,
    sorting: true,
  },
  {
    key: "document",
    label: "Document",
  },
];

export { campusData, campusTableHeading };
