import React from "react";
import { makeStyles } from "@material-ui/core";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { useNavigate } from "react-router-dom";
import { routes } from "../../../routing/routes";

const customStyles = makeStyles({
  root: {
    padding: "100px 0px",
    width: "90%",
    maxWidth: "1216px",
    display: "flex",
    flexDirection: "column",
    marginRight: "auto",
    marginLeft: "auto",
    fontFamily:"Manrope"
  },
  headerBtnWrapper: {
    display: "flex",
    marginTop: "50px",
    gap: "16px",
  },
  header: {
    margin: "26px 0px",
    color: "#271340",
    maxWidth: "50%",
    fontSize: "64px",
    fontWeight: 600,
    lineHeight: 1.1,
    fontFamily:"Manrope"
  },
});

const Campus = () => {
  const classes = customStyles();
  const navigate=useNavigate();
  return (
    <div className={classes.root}>
      <span className="font-size-18  purple-text">
        <span className="grey-text">Home &gt; </span>
        <span>Campus Onboard</span>
      </span>
      <h1 className={classes.header}>Campus Onboard</h1>
      <div className={classes.headerBtnWrapper}>
        <div
          className={"purpleBtn"}
          onClick={() => {
            navigate(routes.CAMPUS_LOGIN);
          }}
        >
          Login <ArrowForwardIcon />
        </div>

        </div>
    </div>
  );
};

export default Campus;
