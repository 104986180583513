import {
  collection,
  doc,
  getDoc,
  getDocs,
  updateDoc,
} from "firebase/firestore";
import { db } from "../config/firebase";
import { COLLECTION } from "../data/constant";
import { redirect } from "react-router-dom";
import moment from "moment";

const createInitialForm = (formData) => {
  const obj = {};

  for (const key in formData) {
    if (formData.hasOwnProperty(key)) {
      obj[key] =
        formData[key].hasOwnProperty("defaultValue") &&
        formData[key].defaultValue !== undefined
          ? formData[key].defaultValue
          : "";
    }
  }

  return obj;
};

const validateRequiredField = (formData, submitData) => {
  const errorData = [];

  for (const key in formData) {
    if (formData.hasOwnProperty(key)) {
      if (
        formData[key].hasOwnProperty("isRequired") &&
        formData[key].isRequired &&
        [undefined, null, ""].includes(submitData[key])
      ) {
        errorData.push(key);
      }
    }
  }

  return errorData;
};

const fetchLatestId = async (name) => {
  const docRef = doc(db, COLLECTION.MASTER, "Counters");
  const docSnap = await getDoc(docRef);
  if (docSnap.exists()) {
    const id = docSnap.data()[name];
    const matches = id.match(/\d+/);
    if (matches && matches.length > 0) {
      const numericPart = parseInt(matches[0], 10);
      const updatedNumericPart = numericPart + 1;
      const updatedId = id.replace(
        /\d+/,
        updatedNumericPart.toString().padStart(matches[0].length, "0")
      );
      return updatedId;
    }
  }
  return null;
};

const updateLatestId = async (name, id) => {
  const docRef = doc(db, COLLECTION.MASTER, "Counters");
  await updateDoc(docRef, { [name]: id });
};



const checkAuthLoader = async (authSession) => {
  const sessionId = localStorage.getItem(authSession.sessionIdName);
  if (!sessionId) {
    return redirect(authSession.redirectPath);
  }
  const docRef = doc(db, authSession.collectionSession, sessionId);
  const docSnap = await getDoc(docRef);
  if (!docSnap.exists()) {
    return redirect(authSession.redirectPath);
  }
  return null;
};

const checkValidMoment = (value, format) => {
  if (value && moment(value).isValid()) {
    return moment(value).format(format);
  }
  return "";
};

export {
  createInitialForm,
  validateRequiredField,
  fetchLatestId,
  updateLatestId,
  checkAuthLoader,
  checkValidMoment,

};
