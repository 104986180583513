import { routes } from "../routing/routes";
import KitchenIcon from "@mui/icons-material/Kitchen";

export const CampusNavTabs = [
  {
    id: "campusPods",
    label: "Campus Pods",
    icon: <KitchenIcon sx={{ fontSize: 25 }} />,
    tabUrl: routes.CAMPUS_PODS,
  },
];
