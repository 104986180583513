const podData = {
  podNumber: {
    key: "podNumber",
    label: "Pod Number",
    placeHolder: "Enter Pod Number",
    type: "text",
  },
  podType: {
    key: "podType",
    label: "Pod Type",
    placeHolder: "Enter Pod Type",
    type: "text",
    isRequired: true,
  },
  manufacture: {
    key: "manufacture",
    label: "Manufacture",
    placeHolder: "Enter Manufacture",
    type: "text",
  },
  campus: {
    key: "campus",
    label: "Campus",
    placeHolder: "Enter Campus",
    type: "text",
  },
};

const podMachineData = {
  podType: {
    key: "podType",
    label: "Pod Type",
    placeHolder: "Enter Pod Type",
    type: "text",
    isRequired: true,
  },
  podName: {
    key: "podName",
    label: "Pod Name",
    placeHolder: "Enter Pod Name",
    type: "text",
    isRequired: true,
  },
  noOfTrays: {
    key: "noOfTrays",
    label: "Number Of Trays",
    placeHolder: "Enter Number Of Trays",
    type: "text",
  },
  footfall: {
    key: "footfall",
    label: "Footfall",
    placeHolder: "Enter Footfall",
    type: "text",
  },
};

const podTableHeading = [
  {
    key: "podNumber",
    label: "Pod Number",
  },
  {
    key: "podType",
    label: "Pod Type",
  },
  {
    key: "vendor",
    label: "Vendor",
  },
  {
    key: "totalPodTransactions",
    label: "Total Transactions",
  },
  {
    key: "status",
    label: "Current Status",
  },
];

const podMachineTableHeading = [
  { ...podMachineData.podType },
  { ...podMachineData.podName },
  { ...podMachineData.noOfTrays },
  { ...podMachineData.footfall },
];

export { podData, podMachineData, podTableHeading, podMachineTableHeading };
