import { Button, Grid, TextareaAutosize, makeStyles } from "@material-ui/core";
import EditIcon from "@mui/icons-material/Edit";
import React from "react";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import TwoInputField from "../../../components/TwoInputField";
import SingleInputField from "../../../components/SingleInputField";
import { vendorData } from "../../../assets/lib/vendor";
import { CircularLoader } from "../../../components/widgets/CircularLoader";
import { ErrorText } from "../../../components";
import { useMediaQuery } from '@mui/material';

const customStyle = makeStyles({
  root: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    position: "relative",
  },
  formContainer: {
    padding: "10px 40px 40px",
    display: "flex",
    flexDirection: "column",
    gap: "20px",
    flexGrow: "1",
    overflowY: "scroll",
  },
  formGroup: {
    display: "flex",
    flexDirection: "column",
    gap: "10px",
  },
  formLabel: {
    fontSize: "16px",
    fontWeight: "bold",
  },
  formInput: {
    padding: "16px",
    border: "1px solid #ccc",
    borderRadius: "4px",
    fontSize: "18px",
  },
  topForm: {
    display: "flex",
  },
  heading: {
    margin: 0,
    fontSize: "22px",
    marginTop: "5px",
    paddingBottom: "4px",
    borderBottom: "1px solid #B0B0B0",
  },
  actionBtn: {
    display: "flex",
    justifyContent: "end",
  },
  editBtn: {
    display: "flex",
    padding: "10px 40px 5px",
    justifyContent: "end",
  },
   // Media query for iPhone 13 and similar screens
   '@media (max-width: 390px)': {
    formContainer: {
      padding: "0px 5px", 
    },
    formLabel: {
      fontSize: "10px", 
    },
    formInput: {
      padding: "12px", 
      fontSize: "8px", 
    },
    heading: {
      fontSize: "18px", 
    },
    actionBtn: {
      justifyContent: "left",
      marginTop:"-10px",
    },
    editBtn: {
      padding: "8px 20px", 
    },
    imgbtn: {
      fontSize:"4px",
      marginLeft:"-20px",
      marginTop:"15px"
     },
  },
});

const Layout = (props) => {
  const {
    isEdit,
    setIsEdit,
    isNewAdd,
    getVendorInput,
    vendorState,
    handleSubmitModal,
    errorFields,
    fetchLoading,
    submitLoading,
    errorOnFetch,
  } = props;
  const classes = customStyle();
  const isPhone = useMediaQuery('(max-width: 390px)');

  return fetchLoading ? (
    <CircularLoader />
  ) : errorOnFetch ? (
    <ErrorText />
  ) : (
    <div className={classes.root}>
      {submitLoading && <CircularLoader isPoistionAbsolute />}
      {!isNewAdd && (
        <div className={classes.editBtn}>
          {!isEdit && (
            <Button
              variant="outlined"
              style={{
                padding: "10px 24px",
                borderRadius: "10px",
              }}
              onClick={() => {
                setIsEdit(true);
              }}
            >
              <span className="flex align-items-center">
                <EditIcon /> Edit Page
              </span>
            </Button>
          )}
        </div>
      )}
      <div className={classes.formContainer}>
        <h3 className={classes.heading}>Vendor Details</h3>

        <div className={classes.topForm}>
          <div className="width-80-per">
            <TwoInputField
              content={vendorData.vendorId}
              value={vendorState.vendorId}
              isEditable={false}
              secondContent={vendorData.vendorName}
              secondValue={vendorState.vendorName}
              getInputData={getVendorInput}
              errorFields={errorFields}
            />
            <TwoInputField
              content={vendorData.GSTNumber}
              value={vendorState.GSTNumber}
              secondContent={vendorData.phoneNumber}
              secondValue={vendorState.phoneNumber}
              getInputData={getVendorInput}
              errorFields={errorFields}
            />
            <TwoInputField
              content={vendorData.alpin}
              value={vendorState.alpin}
              secondContent={vendorData.podsForVendor}
              secondValue={vendorState.podsForVendor}
              getInputData={getVendorInput}
              errorFields={errorFields}
            />
          </div>
          <div className="width-20-per flex-direction-column pd-lt-20 align-items-center">
            <AccountCircleIcon sx={{ fontSize: isPhone ? '60px' : '130px',color: '#808080',marginLeft: isPhone? '-20px':'0px'}} />
            {(isEdit || isNewAdd) && (
              <Button
              className={classes.imgbtn}
                variant="contained"
                color="primary"
                component="span"
                startIcon={<CloudUploadIcon />}
              >
                Upload Image
              </Button>
            )}
          </div>
        </div>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <div className={classes.formGroup}>
              <span className={classes.formLabel}>
                {vendorData.vendorAddress.label} :
              </span>
              <TextareaAutosize
                aria-label="User Input"
                placeholder={vendorData.vendorAddress.placeHolder}
                minRows={3}
                name={vendorData.vendorAddress.key}
                value={vendorState.vendorAddress}
                onChange={getVendorInput}
                style={{
                  fontFamily: `"Open Sans", -apple-system, BlinkMacSystemFont, "Segoe UI",
                  Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji",
                  "Segoe UI Emoji", "Segoe UI Symbol"`,
                  fontSize: "14px",
                }}
              />
            </div>
          </Grid>
        </Grid>
        <TwoInputField
          content={vendorData.location}
          value={vendorState.location}
          secondContent={vendorData.zone}
          secondValue={vendorState.zone}
          getInputData={getVendorInput}
          errorFields={errorFields}
        />
        <TwoInputField
          content={vendorData.rent}
          value={vendorState.rent}
          secondContent={vendorData.noOfPods}
          secondValue={vendorState.noOfPods}
          isSecondEditable={false}
          getInputData={getVendorInput}
          errorFields={errorFields}
        />

        <TwoInputField
          content={vendorData.vendorProducts}
          value={vendorState.vendorProducts}
          secondContent={vendorData.inventory}
          secondValue={vendorState.inventory}
          getInputData={getVendorInput}
          errorFields={errorFields}
        />
        <h3 className={classes.heading}>Contract Details</h3>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <div className={classes.formGroup}>
              <span className={classes.formLabel}>
                {vendorData.commission.label} :
              </span>
              <input
                className={classes.formInput}
                type={vendorData.commission.type}
                placeholder={vendorData.commission.placeHolder}
                name={vendorData.commission.key}
                value={vendorState.commission}
                onChange={getVendorInput}
              />
            </div>
          </Grid>
          <Grid item xs={6}>
            <div className={classes.formGroup}>
              <span className={classes.formLabel}>Documentation:</span>
              {(isEdit || isNewAdd) && (
                <Button
                  variant="contained"
                  color="primary"
                  component="span"
                  style={{
                    width: isPhone ? '115px' : '280px', 
                    height: isPhone ? '30px' : '50px', 
                    fontSize: isPhone ? '6px' : '16px', 
                }}
                  startIcon={<CloudUploadIcon />}
                >
                  Upload Documents
                </Button>
              )}
            </div>
          </Grid>
        </Grid>
        <h3 className={classes.heading}>Contact person Details</h3>
        <TwoInputField
          content={vendorData.contactPersonName}
          value={vendorState.contactPersonName}
          secondContent={vendorData.contactPersonPhone}
          secondValue={vendorState.contactPersonPhone}
          getInputData={getVendorInput}
          errorFields={errorFields}
        />
        <div className={classes.actionBtn}>
          {(isEdit || isNewAdd) && (
            <Button
              variant="contained"
              style={{
                color: "white",
                backgroundColor: "#00C853",
                minWidth: isPhone ? "140px" : "200px",
                padding: isPhone ? "8px 20px" : "12px 46px",
                borderRadius: isPhone ? "8px" : "10px",
                fontSize: isPhone ? "10px" : "16px", 
              }}
              onClick={handleSubmitModal}
            >
              {isEdit ? "Save Changes" : "Submit"}
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

export default Layout;
