import React from "react";
import Layout from "./Layout";
import { useState } from "react";
import useTableFunction from "../../../hooks/useTableFunction";
import { COLLECTION, COLLECTION_COUNTER ,SESSION} from "../../../data/constant";
import { productData } from "../../../assets/lib/product";
import {
  createInitialForm,
  fetchLatestId,
  updateLatestId,
  validateRequiredField,
} from "../../../common/common";
import {
  addDoc,
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  updateDoc,
 
} from "firebase/firestore";
import { db } from "../../../config/firebase";
import { useEffect } from "react";

const VendorManageProducts = () => {
  const [prodState, setProdState] = useState(createInitialForm(productData));
  const [campusData, setCampusData] = useState([]);
  const [vendorData, setVendorData] = useState([]);
  const [errorFields, setErrorFields] = useState([]);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [isFetchingPodNum, setIsFetchingPodNum] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [errorOnFetch, setErrorOnFetch] = useState(false);
  const [campusMenu, setCampusMenu] = useState(null);
  const [vendorMenu, setVendorMenu] = useState(null);
  const [vendorId, setVendorId] = useState(null);
  const [loading, setLoading] = useState(true);
  const [dataChanged, setDataChanged] = useState(false);
  const [pData, setPData] = useState([]);
  const [vendorProductData, setVendorProductData] = useState([]);
  const [gid, setGid] = useState([]);


  let today = new Date()

  useEffect(() => {
    const fetchCampusVendorData = async () => {

      const collectionProductRef = collection(db, COLLECTION.VENDOR);
      const baseVendorQuery = query(collectionProductRef);

      const dataVendorSnapshot = await getDocs(baseVendorQuery);

      const collectionVendorData = dataVendorSnapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));
      setVendorData(collectionVendorData);
    };
    fetchCampusVendorData();
   
  }, []);

  useEffect(() => {
    const fetchVendorData = async () => {
      try {
        
        const sessionId = localStorage.getItem(SESSION.VENDOR_SESSION_ID);
        
        if (!sessionId) {
          throw new Error("Vendor session ID is not found in local storage.");
        }

        // Fetch vendor session data
        const vendorSessionDocRef = doc(db, COLLECTION.VENDOR_SESSION, sessionId);
        const vendorSessionDocSnap = await getDoc(vendorSessionDocRef);
        if (!vendorSessionDocSnap.exists()) {
          throw new Error("Vendor session document not found");
        }

        const vendorData = vendorSessionDocSnap.data();
        setVendorId(vendorData.vendorID);
        const vendorDetailsDocRef = doc(db, COLLECTION.VENDOR,vendorData.vendorId );
        const vendorDetailsDocSnap = await getDoc(vendorDetailsDocRef);
        const vendorproductsdata  = vendorDetailsDocSnap.data().vendorProducts;
        setPData(vendorproductsdata)

        if (!vendorDetailsDocSnap.exists()) {
          throw new Error("Vendor details document not found");
        }

        setVendorData(vendorDetailsDocSnap.data());
        
        setLoading(false);

        const vid = vendorDetailsDocSnap.data().vendorId
       
        const sufpre = "P"
        
        const id = Math.floor(Math.random()*(999-100+1)+100)
        
        const merged = vid.concat(sufpre,id)
        
        setGid(merged)

      } catch (error) {
        console.error("Error fetching vendor data:", error);
        setLoading(false);
      }
    };
    fetchVendorData();

  }, [dataChanged]); 


  const getProductInput = (event) => {
    const name = event.target.name;
    let value = event.target.value;

    const inputType = event.target.type;
    if (value && inputType === "number") {
      value = parseInt(value);
    }
    if (errorFields.includes(name) && ![undefined, null, ""].includes(value)) {
      setErrorFields((prevState) => prevState.filter((key) => key !== name));
    }
    setProdState((prevState) => ({ ...prevState, [name]: value }));
  };


  const handleSubmitModal = async () => {
    const error = validateRequiredField(productData, prodState);
    setErrorFields(error);
    setSubmitLoading(true);
    if (error.length === 0) {
      try {
        // Append
        
        setProdState(createInitialForm(productData));//Setting user entered  data to state var
        prodState["productId"] = gid
        const array = prodState; //Assigning the user data to a veriable
        {console.log("Entered data:",array)}

        let productdata = pData //Assigning the vendor data to a veriable
        {console.log("vendor  productdata:",productdata)}

        let mergeddata = productdata.concat(array) //merging cendor array with user data array
        {console.log("vendor merged product data:",mergeddata)}

        const sessionId = localStorage.getItem(SESSION.VENDOR_SESSION_ID);
        if (!sessionId) {
          throw new Error("Vendor session ID is not found in local storage.");
        }
        // Fetch vendor session data
        const vendorSessionDocRef = doc(db, COLLECTION.VENDOR_SESSION, sessionId);
        const vendorSessionDocSnap = await getDoc(vendorSessionDocRef);
        if (!vendorSessionDocSnap.exists()) {
          throw new Error("Vendor session document not found");
        }

        const vendorData = vendorSessionDocSnap.data();
        setVendorId(vendorData.vendorID);

        const vendorDetailsDocRef = doc(db, COLLECTION.VENDOR,vendorData.vendorId );
        const vendorDetailsDocSnap = await getDoc(vendorDetailsDocRef);
        const vendorDetailsDocSnapdata = vendorDetailsDocSnap.data();

        vendorDetailsDocSnapdata.vendorProducts =mergeddata;
        {console.log("Final Vendor Data",vendorDetailsDocSnapdata)}
                
                    // save edited data
                    const docRef = doc(db,COLLECTION.VENDOR,vendorData.vendorId);
                    await updateDoc(docRef, vendorDetailsDocSnapdata);

        setIsModalOpen(false);
        const fetchVendorData = async () => {
          try {
            
            const sessionId = localStorage.getItem(SESSION.VENDOR_SESSION_ID);
            
            if (!sessionId) {
              throw new Error("Vendor session ID is not found in local storage.");
            }
    
            // Fetch vendor session data
            const vendorSessionDocRef = doc(db, COLLECTION.VENDOR_SESSION, sessionId);
            const vendorSessionDocSnap = await getDoc(vendorSessionDocRef);
            if (!vendorSessionDocSnap.exists()) {
              throw new Error("Vendor session document not found");
            }
    
            const vendorData = vendorSessionDocSnap.data();
            setVendorId(vendorData.vendorID);
            const vendorDetailsDocRef = doc(db, COLLECTION.VENDOR,vendorData.vendorId );
            const vendorDetailsDocSnap = await getDoc(vendorDetailsDocRef);
            const vendorproductsdata  = vendorDetailsDocSnap.data().vendorProducts;
    
            setPData(vendorproductsdata)
    
            if (!vendorDetailsDocSnap.exists()) {
              throw new Error("Vendor details document not found");
            }
    
            setVendorData(vendorDetailsDocSnap.data());
            
            setLoading(false);
          } catch (error) {
            console.error("Error fetching vendor data:", error);
            setLoading(false);
          }
        };

        fetchVendorData()
        window.location.reload()
     
      } catch (error) {
        console.log("error", error);
        setErrorOnFetch(true);
      }
    }
    setSubmitLoading(false);
    refetchTableData()
  };
  

  const productId = async () => {
    setIsFetchingPodNum(true);
    const latestId = await fetchLatestId(COLLECTION_COUNTER.VENDOR);
    setProdState((prevState) => ({
      ...prevState,
      [productData.productId.key]: latestId,
    }));
    setIsFetchingPodNum(false);
  };

  const handledelete = async (row) => {
    try {
    const sessionId = localStorage.getItem(SESSION.VENDOR_SESSION_ID);
    const vendorSessionDocRef = doc(db, COLLECTION.VENDOR_SESSION, sessionId);
    const vendorSessionDocSnap = await getDoc(vendorSessionDocRef);
    const vendorData = vendorSessionDocSnap.data();
    setVendorId(vendorData.vendorID);
    const vendorDetailsDocRef = doc(db, COLLECTION.VENDOR,vendorData.vendorId );
    const vendorDetailsDocSnap = await getDoc(vendorDetailsDocRef);
    let vendorproductsdata  = vendorDetailsDocSnap.data().vendorProducts
  
    console.log("before",vendorproductsdata); 

    const targetId = row.productId;
    let foundKey = null;
    
    for (const key in vendorproductsdata) {
      if (vendorproductsdata.hasOwnProperty(key)) {
        if (vendorproductsdata[key].productId === targetId) {
          foundKey = key;
          break; 
         // Exit loop once the match is found
        }
      }
    }
    console.log("KEY",foundKey)
    vendorproductsdata.splice(foundKey, 1);
    console.log("FINAL",vendorproductsdata)
   await updateDoc(vendorDetailsDocRef,{vendorProducts: vendorproductsdata})
  } catch (error) {
    console.log("error", error);
   }
   window.location.reload()
  };

  const {
    tableData,
    isTableLoading,
    currentPage,
    totalLength,
    handlePageChange,
    handlePageSize,
    handleSearchQuery,
    handleTableSorting,
    refetchTableData,
  } = useTableFunction({
    collectionPath: COLLECTION.VENDOR.vendorProducts,
  });
  return (
    <Layout
      productTableData={tableData}
      isTableLoading={isTableLoading}
      currentPage={currentPage}
      totalLength={totalLength}
      handlePageChange={handlePageChange}
      handlePageSize={handlePageSize}
      handleSearchQuery={handleSearchQuery}
      handleTableSorting={handleTableSorting}
      isModalOpen={isModalOpen}
      setIsModalOpen={setIsModalOpen}
      handleSubmitModal={handleSubmitModal}
      prodState={prodState}
      getProductInput={getProductInput}
      errorFields={errorFields}
      productId={productId}
      loadingModal={isFetchingPodNum || submitLoading}
      campusData={campusData}
      campusMenu={campusMenu}
      setCampusMenu={setCampusMenu}
      vendorData={vendorData}
      vendorMenu={vendorMenu}
      setVendorMenu={setVendorMenu}
      vpdata = {pData}
      gid={gid}
      handledelete={handledelete}
  
    />
  );
};

export default VendorManageProducts;
