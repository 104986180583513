import React from "react";
import { useState } from "react";
import { Button, makeStyles } from "@material-ui/core";
import { Menu, MenuItem, TextField } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useEffect } from "react";
import { useRef } from "react";
import { useMediaQuery } from '@mui/material';

const customStyle = (width = "250px") =>
  makeStyles({
    searchBar: {
      display: "flex",
      gap: "10px",
    },
    formInput: {
      padding: "16px",
      border: "1px solid #ccc",
      borderRadius: "4px",
      fontSize: "18px",
      width: width,
    },
    '@media (max-width: 390px)': {
      searchBar: {
        flexDirection: "row", 
        gap: "8px",
        height:"45px",
      },
      formInput: {
        width: "100%",
        height:"12px", 
        fontSize: "7px",
      },
    },
  });

const SearchTableInput = (props) => {
  const { tableHeading, handleSearchQuery, width } = props;
  const [searchQuery, setSearchQuery] = useState("");
  const [searchMenu, setSearchMenu] = useState(tableHeading[0]);
  const [anchorEl, setAnchorEl] = useState(null);
  const classes = customStyle(width)();
  const isFirstRender = useRef(true);
  const isPhone = useMediaQuery('(max-width: 390px)');

  const onChangeMenuItem = (item) => {
    setSearchMenu(item);
    setAnchorEl(null);
  };

  useEffect(() => {
    if (isFirstRender.current) {
      // Ignoring the call of the search function for mounting
      isFirstRender.current = false;
      return;
    }
    let timer;
    timer = setTimeout(() => {
      handleSearchQuery(searchMenu.key, searchQuery);
    }, 800);
    return () => {
      clearTimeout(timer);
    };
  }, [searchMenu, searchQuery]);

  return (
    <div className={classes.searchBar}>
      <Button
        aria-controls="dropdown-menu"
        aria-haspopup="true"
        onClick={(event) => setAnchorEl(event.currentTarget)}
        variant="contained"
        color="primary"
        style={{ textTransform: "none", fontSize: isPhone ? '8px' : '16px' }}
        endIcon={<KeyboardArrowDownIcon />}
      >
        {searchMenu.label}
      </Button>
      <Menu
        id="dropdown-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
      >
        {tableHeading.map((heading, index) => (
          <MenuItem onClick={() => onChangeMenuItem(heading)} key={index}>
            {heading.label}
          </MenuItem>
        ))}
      </Menu>
      <input
        className={classes.formInput}
        type={"text"}
        placeholder={"Search table"}
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
      />
    </div>
  );
};

export default SearchTableInput;
