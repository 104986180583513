import { useState, useEffect } from "react";
import {
  collection,
  getDocs,
  query,
  orderBy,
  where,
  startAfter,
  limit,
  getCountFromServer,
  doc,
  getDoc,
} from "firebase/firestore";
import { db } from "../config/firebase";
import moment from "moment";

const useTableFunction = ({
  collectionPath,
  defaultOrderBy,
  dynamicCondition,
  conditionOrderBy,
}) => {
  const [tableData, setTableData] = useState([]);
  const [isTableLoading, setIsTableLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalLength, setTotalLength] = useState(0);
  const [pageSize, setPageSize] = useState(5);
  const [searchData, setSearchData] = useState({ column: "", query: "" });
  const [searchDateRange, setSearchDateRange] = useState({
    fromDate: "",
    toDate: "",
    column: "",
  });
  const [orderByField, setOrderByField] = useState({
    column: "",
    direction: "",
  });
  const [lastDocument, setLastDocument] = useState(null);

  useEffect(() => {
    fetchData();
  }, [currentPage, searchData, pageSize, orderByField, searchDateRange]);

  const fetchData = async () => {
    try {
      setIsTableLoading(true);

      const collectionRef = collection(db, collectionPath);

      let baseQuery = defaultOrderBy
        ? query(collectionRef, orderBy(defaultOrderBy))
        : collectionRef;

      if (orderByField.column && orderByField.direction) {
        baseQuery = query(
          collectionRef,
          orderBy(orderByField.column, orderByField.direction)
        );
      }
      if (searchData.column && searchData.query) {
        if (searchData.column === "id") {
          const docRef = doc(db, collectionPath, searchData.query);
          const docSnap = await getDoc(docRef);
          const docData = { ...docSnap.data(), id: docSnap.id };
          setTableData([docData]); // Set the table data with the document data
          setIsTableLoading(false);
          return;
        }
        baseQuery = query(
          // baseQuery,
          collectionRef,
          orderBy(searchData.column),
          // where(searchData.column, "==", searchData.query)
          where(searchData.column, ">=", searchData.query),
          where(searchData.column, "<=", searchData.query + "\uf8ff")
        );
      }

      if (dynamicCondition) {
        const condition = await dynamicCondition();
        if (condition.column === "id") {
          const docRef = doc(db, collectionPath, condition.query);
          const docSnap = await getDoc(docRef);
          const docData = { ...docSnap.data(), id: docSnap.id };
          setTableData([docData]); // Set the table data with the document data
          setIsTableLoading(false);
          return;
        } else {
          baseQuery = query(
            // baseQuery,
            collectionRef,
            where(condition.column, condition.operator, condition.query)
          );
        }
      }

      if (conditionOrderBy) {
        baseQuery = query(baseQuery, orderBy(conditionOrderBy));
      }

      if (
        searchDateRange.fromDate &&
        searchDateRange.toDate &&
        searchDateRange.column
      ) {
        const fromDate = moment(searchDateRange.fromDate).toDate();
        const toDate = moment(searchDateRange.toDate).toDate();
        if(dynamicCondition){
          const condition = await dynamicCondition();
          baseQuery = query(
            collectionRef,
            orderBy(searchDateRange.column),
            where(condition.column, condition.operator, condition.query),
            where(
              searchDateRange.column,
              ">=",
              fromDate instanceof Date ? fromDate : null
            ),
            where(
              searchDateRange.column,
              "<=",
              toDate instanceof Date ? toDate : null
            )
          );
          console.log("dynamic")
        }
        
        else{
          baseQuery = query(
            collectionRef,
            orderBy(searchDateRange.column),
            where(
              searchDateRange.column,
              ">=",
              fromDate instanceof Date ? fromDate : null
            ),
            where(
              searchDateRange.column,
              "<=",
              toDate instanceof Date ? toDate : null
            )
          );
        }
      }

      const countSnapshot = await getCountFromServer(baseQuery);
      setTotalLength(countSnapshot.data().count);

      let paginatedQuery = query(
        baseQuery,
        // TODO: Modify this for previous page
        currentPage > 0 ? startAfter(lastDocument) : undefined,
        limit(pageSize)
      );

      const dataSnapshot = await getDocs(paginatedQuery);

      setLastDocument(dataSnapshot.docs[dataSnapshot.docs.length - 1]);
      const paginatedData = dataSnapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));

      setTableData(paginatedData);
      setIsTableLoading(false);
    } catch (error) {
      setIsTableLoading(false);
      console.log("Error ", error);
    }
  };

  const refetchTableData = () => {
    fetchData();
  };

  const handlePageChange = (event, newPage) => {
    setCurrentPage(newPage);
  };

  const handleSearchQuery = (column = "", query = "") => {
    if (
      searchData.query !== query ||
      (searchData.query && searchData.column !== column)
    ) {
      setSearchData({ column, query });
    }
  };

  const handlePageSize = (pageSize) => {
    setCurrentPage(0);
    setPageSize(pageSize);
  };

  const handleTableSorting = (column, direction) => {
    setOrderByField({ column, direction });
  };

  const handleSearchDate = (fromDate, toDate, searchColumn) => {
    setCurrentPage(0);
    setSearchDateRange({
      fromDate,
      toDate,
      column: searchColumn,
    });
  };

  return {
    tableData,
    isTableLoading,
    currentPage,
    totalLength,
    handleSearchQuery,
    handlePageChange,
    handlePageSize,
    handleTableSorting,
    handleSearchDate,
    refetchTableData,
  };
};

export default useTableFunction;