export const contactUsData = {
  contactName: {
    key: "contactName",
    label: "Name",
    placeHolder: "Your Name",
    type: "text",
  },
  contactEmail: {
    key: "contactEmail",
    label: "Email",
    placeHolder: "Work Email",
    type: "text",
  },
  contactCompany: {
    key: "contactCompany",
    label: "Company",
    placeHolder: "Company Name",
    type: "text",
  },
  message: {
    key: "message",
    label: "Message",
    placeHolder: "Your Message",
    type: "text",
  },
};
