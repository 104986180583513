import React, { useEffect, useRef, useState } from "react";
import { COLLECTION, COLLECTION_COUNTER ,SESSION} from "../../../data/constant";
import {
  addDoc,
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  updateDoc,
  getCountFromServer,
  where, 
  Timestamp
} from "firebase/firestore";
import { db } from "../../../config/firebase";
import Layout from "./Layout";

const Dashboard = () => {

  const [userscount,setUserscount] = useState("")
  const [totaltransaction,setTotaltransaction] = useState("")
  const [todaytransaction,setTodaytransaction] = useState("")
  const [walletamount,setWalletamount] = useState("")
  const [alpinperday,setAlpinperday] = useState("")

  useEffect(() => {
    const countDocumentsPerDay = async () => {
        const usercollectionRef = collection(db,COLLECTION.USER); 
        const usercollectionSnapshot = await getDocs(usercollectionRef);
        const userData = usercollectionSnapshot.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
        }));
        const updatedDocuments = userData.map(doc => ({
          ...doc,
          createdTimestamp: doc.createdTimestamp ? new Date(doc.createdTimestamp * 1000).toISOString().split('T')[0] : null
            }));
        // console.log("updated data",updatedDocuments)

        const countDocumentsByDate = (docs) => {
        const counts = {};
        const today = new Date();
        
        // Calculate the date 30 days ago
        const DaysAgo = new Date(today);
        DaysAgo.setDate(today.getDate() - 15);
    
        // Initialize counts for each date in the range
        for (let d = new Date(DaysAgo); d <= today; d.setDate(d.getDate() + 1)) {
            const formattedDate = d.toISOString().split('T')[0]; // Get YYYY-MM-DD format
            counts[formattedDate] = 0; // Initialize to zero
        }
    
        // Count occurrences of each date from the documents
        docs.forEach(doc => {
            const docDate = new Date(doc.createdTimestamp); // Convert to Date object
    
            // Check if the created date is valid
            if (!isNaN(docDate.getTime())) {
                const formattedDate = docDate.toISOString().split('T')[0]; // Get YYYY-MM-DD format
    
                // Only count if the document date is within the last 30 days
                if (formattedDate in counts) {
                    counts[formattedDate] += 1; // Increment count
                }
            }
        });
    
        return counts;
    };
    
    // Call the function and log the results
    const documentCounts = countDocumentsByDate(updatedDocuments);
    setAlpinperday(documentCounts)
         
      }
    countDocumentsPerDay();
  }, []);


  useEffect(() => {
    const todayTransaction = async() =>{
      const TransactionRef = collection(db, COLLECTION.TRANSACTIONS);
      const TransactionSnapshot = await getDocs(TransactionRef);
      const TransactionData = TransactionSnapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));
      const filteredTransactions = TransactionData.filter(obj => 'transactionAmount' in obj);
      
        // Get today's date in 'YYYY-MM-DD' format
        const today = new Date().toISOString().split('T')[0];

        // Function to convert Firestore timestamp to a date string
        const convertFirestoreTimestamp = (timestamp) => {
          return new Date(timestamp.seconds * 1000).toISOString().split('T')[0];
        };

        // Filter transactions by today's date
        const todayTransactions = filteredTransactions.filter(obj => {
          const transactionDate = convertFirestoreTimestamp(obj.transactionTime);
          return transactionDate === today;
        });

        const totalTransactionAmount = todayTransactions.reduce((acc, obj) => acc + obj.transactionAmount, 0);
        setTodaytransaction(totalTransactionAmount)
    }
    todayTransaction();
  }, []);

  useEffect(() => {
    const totalTransaction = async() =>{
      const TransactionRef = collection(db, COLLECTION.TRANSACTIONS);
      const TransactionSnapshot = await getDocs(TransactionRef);
      const TransactionData = TransactionSnapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));
      const filteredTransactions = TransactionData.filter(obj => 'transactionAmount' in obj);
      const totalTransactionAmount = filteredTransactions.reduce((acc, obj) => acc + obj.transactionAmount, 0);
      setTotaltransaction(totalTransactionAmount)
    }
    totalTransaction();
  }, []);

  useEffect(() => {
    const totalWallet = async() =>{
      const usercollectionRef = collection(db,COLLECTION.USER); 
      const usercollectionSnapshot = await getDocs(usercollectionRef);
      const userData = usercollectionSnapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));
      // console.log("trans",userData)
       const wallet = userData.reduce((acc, obj) => acc + obj.walletAmount, 0);
     //  console.log(wallet)
       setWalletamount(wallet)
    }
    totalWallet();
  }, []);

  useEffect(() => {
    const userCount = async() =>{
      const collectionRef = collection(db,COLLECTION.USER); 
      const snapshot = await getCountFromServer(collectionRef)
      const count = snapshot.data().count
      setUserscount(count)
    }
    userCount();
  }, []);

  return (
    <Layout 
    userscount={userscount}
    totaltransaction={totaltransaction}
    todaytransaction={todaytransaction}
    walletamount={walletamount}
    alpinperday={alpinperday}
    />
  );
};

export default Dashboard;
