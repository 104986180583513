import React, { useState } from "react";
import { NavBar, TopBar } from "..";
import { makeStyles } from "@material-ui/core";

const customStyle = makeStyles({
  errorContainer: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    textAlign: "center",
    justifyContent: "center",
  },
});

function ErrorText(props) {
  const { subText } = props;
  const [fullSideBar, setFullSideBar] = useState(true);

  const classes = customStyle();

  return (
    <div className={classes.errorContainer}>
      <h1>An error occured! </h1>
      {subText ? <h4>{subText} </h4> : <h4> {"Please try agian "}</h4>}
    </div>
  );
}

export default ErrorText;
