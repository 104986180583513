import React from "react";

import Layout from "./Layout";
import useTableFunction from "../../../hooks/useTableFunction";
import { vendorData } from "../../../assets/lib/vendor";
import { COLLECTION } from "../../../data/constant";

export default function VendorManagement() {
  const {
    tableData,
    isTableLoading,
    currentPage,
    totalLength,
    handlePageChange,
    handlePageSize,
    handleSearchQuery,
    handleTableSorting,
  } = useTableFunction({
    collectionPath: COLLECTION.VENDOR,
    defaultOrderBy: vendorData.vendorId.key,
  });

  return (
    <>
      <Layout
        vendorTableData={tableData}
        isTableLoading={isTableLoading}
        currentPage={currentPage}
        totalLength={totalLength}
        handlePageChange={handlePageChange}
        handlePageSize={handlePageSize}
        handleSearchQuery={handleSearchQuery}
        handleTableSorting={handleTableSorting}
      />
    </>
  );
}
