import React, { useEffect, useState } from "react";
import { NavBar, TopBar } from "../../components";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { deleteDoc, doc } from "firebase/firestore";
import { db } from "../../config/firebase";
import { COLLECTION, SESSION } from "../../data/constant";
import { routes } from "../../routing/routes";
import { CampusNavTabs } from "../../data/CampusNavTabs";

const CampusRootLayout = () => {
  const [fullSideBar, setFullSideBar] = useState(true);
  const [showNavTopBar, setShowNavTopBar] = useState(false);
  const { pathname } = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const showTabs = CampusNavTabs.some((tab) => pathname.includes(tab.tabUrl));
    setShowNavTopBar(showTabs);
  }, [pathname, CampusNavTabs]);

  const logout = async () => {
    const sessionId = localStorage.getItem(SESSION.CAMPUS_SESSION_ID);
    localStorage.clear();
    const docRef = doc(db, COLLECTION.CAMPUS_SESSION, sessionId);
    await deleteDoc(docRef);
    navigate(routes.CAMPUS_LOGIN);
  };

  return (
    <main className="root-layout">
      <div className="flex-direction-column width-100-per height-100-per">
        {showNavTopBar ? (
          <>
            <TopBar
              fullSideBar={fullSideBar}
              setFullSideBar={setFullSideBar}
              handleLogout={logout}
            />
            <div className="afterAuthorize">
              <NavBar fullSideBar={fullSideBar} tabs={CampusNavTabs} />
              <Outlet />
            </div>
          </>
        ) : (
          <Outlet />
        )}
      </div>
    </main>
  );
};

export default CampusRootLayout;
