  import React, { useEffect, useRef } from "react";
  import Layout from "./Layout";
  import useTableFunction from "../../../hooks/useTableFunction";
  import { COLLECTION, SESSION } from "../../../data/constant";
  import { db } from "../../../config/firebase";
  import { doc, getDoc,getDocs,where,query ,collection} from "firebase/firestore";


  // const fetchTransactionsByPhoneNumber = async (phoneNumber) => {
  //   try {
      
  //     const q = query(db, COLLECTION.TRANSACTIONS, where("phoneNumber", "==", (phoneNumber)));
    
  //     const querySnapshot = await getDocs(q);

  //     const transactionsData = [];

  //     querySnapshot.forEach((doc) => {
  //       transactionsData.push(doc.data());
  //     });

  //     return transactionsData;
  //   } catch (error) {
  //     console.error("Error fetching transactions:", error);
  //   }
  // };


  const UserTransaction = () => {
    const dynamicConditionCB = async () => {
      let phoneNumber = "";
      const sessionId = localStorage.getItem(SESSION.USER_SESSION_ID);
      const docRef = doc(db, COLLECTION.USER_SESSION, sessionId);
      const docSnap = await getDoc(docRef);
      const data = docSnap.data();
      // console.log(data);
      
      // console.log(typeof(data.phoneNumber))

      // const userID=data.userID;
      // console.log(data.userID);

      // const userdocRef = doc(db, COLLECTION.USER, userID);
      // const userdocSnap = await getDoc(userdocRef);
      // const userdata = userdocSnap.data();
      // console.log(userdata)
      
      // phoneNumber=String(userdata.phoneNumber);
      // console.log((typeof(phoneNumber)));


  //     const transdocRef = doc(db, COLLECTION.TRANSACTIONS, "7eXLfFrU6nt5mnrZed4ts5");
  //     const transdocSnap = await getDoc(transdocRef);
  //     const transdata = transdocSnap.data();
  // console.log(transdata)    



  // const fff=fetchTransactionsByPhoneNumber((userdata.phoneNumber));
  // console.log(fff);

      if (docSnap.exists()) {
        const data = docSnap.data();
        phoneNumber = String(data.phoneNumber);
      }
      return {
        column: "phoneNumber",
        operator: "==",
        query: phoneNumber,
      };
    };

    const {
      tableData,
      isTableLoading,
      currentPage,
      totalLength,
      handlePageChange,
      handlePageSize,
      handleSearchQuery,
      handleTableSorting,
      refetchTableData,
    } = useTableFunction({
      collectionPath: COLLECTION.TRANSACTIONS,
      // defaultOrderBy: podMachineData.podType.key,
      dynamicCondition: dynamicConditionCB,
    });

    return (
      <Layout
        userTransactionData={tableData}
        isTableLoading={isTableLoading}
        currentPage={currentPage}
        totalLength={totalLength}
        handlePageChange={handlePageChange}
        handlePageSize={handlePageSize}
        handleSearchQuery={handleSearchQuery}
        handleTableSorting={handleTableSorting}
      />
    );
  };

  export default UserTransaction;
