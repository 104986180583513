import { Button, makeStyles } from "@material-ui/core";
import React from "react";
import { CustomTable, SearchTableInput } from "../../../components";
import { useNavigate } from "react-router-dom";
import { routes } from "../../../routing/routes";

const customStyle = makeStyles({
  root: {
    padding: "20px 40px",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    gap: "30px",
    overflow: "hidden",
  },
  buttonContainer: {
    height: "50px",
    display: "flex",
    justifyContent: "space-between",
  },
  actionBtn: {
    display: "flex",
    gap: "30px",
  },
  '@media (max-width: 390px)': {
    root: {
      padding: "5px 5px", 
      gap: "60px", 
      width:"80vw",
      height:"90vh"
    },
    buttonContainer: {
      height: "35px",
      justifyContent: "space-evenly",
      flexWrap: "wrap",
      marginLeft:"-50px",
    },
    actionBtn: {
      marginTop:"5px",
      marginLeft:"30px",
      gap: "5px",
      flexDirection: "row", 
      height: "35px",
      width:"75vw"
    },
  },
});

const Layout = (props) => {
  const {
    campusTableHeading,
    campusTableData,
    isTableLoading,
    currentPage,
    totalLength,
    handlePageChange,
    handlePageSize,
    handleSearchQuery,
    handleTableSorting,
  } = props;

  const classes = customStyle();
  const navigate = useNavigate();
  const actions = [
    {
      label: "View",
      onClick: (row) => {
        navigate(`${routes.CAMPUS_INFO}/${row.id}`, {
          state: { addCampus: false, editCampus: false },
        });
      },
      bgColor: "#2196F3",
    },
    {
      label: "Edit",
      onClick: (row) => {
        navigate(`${routes.CAMPUS_INFO}/${row.id}`, {
          state: { addCampus: false, editCampus: true },
        });
      },
      bgColor: "#4CAF50",
    },
  ];

  return (
    <div className={classes.root}>
      <div className={classes.buttonContainer}>
        <SearchTableInput
          tableHeading={campusTableHeading}
          handleSearchQuery={handleSearchQuery}
        />
        <div className={classes.actionBtn}>
          <Button
            variant="contained"
            style={{
              color: "white",
              backgroundColor: "#FF5722",
              padding: "12px 24px",
              borderRadius: "10px",
            }}
            onClick={() =>
              navigate(routes.CAMPUS_INFO, {
                state: { addCampus: true, editCampus: false },
              })
            }
          >
            Add Campus
          </Button>
        </div>
      </div>
      <CustomTable
        columns={campusTableHeading}
        data={campusTableData}
        actions={actions}
        isLoading={isTableLoading}
        currentPage={currentPage}
        totalLength={totalLength}
        handlePageChange={handlePageChange}
        handlePageSize={handlePageSize}
        handleTableSorting={handleTableSorting}
      />
    </div>
  );
};

export default Layout;
