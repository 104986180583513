import React from "react";
import CircularProgress from "@mui/material/CircularProgress";

export const CircularLoader = (props) => {
  const { isPoistionAbsolute, height, zIndex = 2 } = props;
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: height ? height : "100%",
        width: "100%",
        top: 0,
        left: 0,
        position: isPoistionAbsolute ? "absolute" : "static",
        zIndex: zIndex,
      }}
    >
      <CircularProgress />
    </div>
  );
};
