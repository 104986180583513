import React from "react";
import { makeStyles, TextField } from "@material-ui/core";
import CustomModal from "../../../components/CustomModal";

const useStyles = makeStyles({
  formContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "20px",
    maxHeight: "55vh", 
    overflowY: "auto",
  },
  itemContainer: {
    border: "1px solid #ccc", 
    borderRadius: "8px", 
    padding: "10px",
    marginBottom:"10px" 
  },
  textField: {
    width: "100%",
  },
});

const InventoryModal = (props) => {
  const classes = useStyles();
  const { isOpen, onClose, itemData } = props;

  return (
    <>
    <CustomModal
      open={isOpen}
      onClose={onClose}
      heading="Inventory Items"
      hideSubmitButton={true}  
    >
      <div className={classes.formContainer}>
        {Object.entries(itemData).map(([itemName, itemDetails], index) => (
          <div key={index} className={classes.itemContainer}>
            <TextField
              className={classes.textField}
              label="Item Name"
              value={itemName}
              InputProps={{ readOnly: true }}
            />
            {Object.entries(itemDetails).map(([key, value], idx) => (
              <TextField
                key={idx}
                className={classes.textField}
                label={key}
                value={value}
                InputProps={{ readOnly: true }}
              />
            ))}
          </div>
        ))}
      </div>
    </CustomModal>
    </>
  );
};

export default InventoryModal;
