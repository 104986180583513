import React, { useEffect, useRef } from "react";
import Layout from "./Layout";

const TutorialsAdmin = () => {
  return (
    <Layout />
  );
};

export default TutorialsAdmin;
