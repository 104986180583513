import React from "react";
import { makeStyles } from "@material-ui/core";
import Button from "@mui/material/Button";
import SignupBackground from "../../../assets/Images/signupBackground.jpg";
import TextAnimation from "../../../assets/Images/textAnimation.png";
import { SingleInputField } from "../../../components";
import { signUpData } from "../../../assets/lib/login";
import { useNavigate } from "react-router-dom";
import { routes } from "../../../routing/routes";
import { CircularLoader } from "../../../components/widgets/CircularLoader";

const customStyle = makeStyles({
  leftContent: {
    // background: "rgb(74 0 255 / 64%)",
    background: `url(${SignupBackground}) top left repeat`,
    backgroundSize: "cover",
    top: "0",
    bottom: "0",
    minHeight: "100vh", // Change to minHeight: "100vh",
    textAlign: "right",
    opacity: "1",
    position: "relative",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "30px 50px",
  },
  rightContent: {
    padding: "40px",
  },
  colorBg: {
    position: "absolute",
    content: "''",
    top: "0",
    left: "0",
    right: "0",
    bottom: "0",
    background: "rgb(74 0 255 / 64%)",
  },
  animatedText: {
    display: "block",
    textShadow: "0 0 80px rgba(255, 255, 255, 0.5)",
    background: `url(${TextAnimation}) repeat-y`,
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    WebkitAnimation: "aitf 80s linear infinite",
    WebkitTransform: "translate3d(0, 0, 0)",
    WebkitBackfaceVisibility: "hidden",
    fontSize: "50px",
    margin: "0px",
  },
  information: {
    display: "flex",
    height: "100%",
    width: "100%",
    flexDirection: "column",
    justifyContent: "center",
    justifyContent: "center",
    gap: "20px",
    color: "white",
    zIndex: "2",
  },
  inputContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "10px",
  },
  '@media (max-width: 390px)': {
    leftContent: {
      padding: "20px 15px", 
      flexDirection: "column",
    },
    rightContent: {
      padding: "5px 10px", 
    },
    animatedText: {
      fontSize: "30px", 
    },
    information: {
      gap: "10px", 
    },
    inputContainer: {
      gap: "5px",
    },
  }
});

const Layout = (props) => {
  const {
    submitLoading,
    signUpState,
    errorFields,
    getSignUpInput,
    handleSubmitSignUp,
    handleOtpSendClick,
    otp,
    isInvalidOtp,
    phoneNoNot10Digit,
    alpinNot4Digit
  } = props;
  const classes = customStyle();
  const navigate = useNavigate();

  return (
    <div className="width-100-per flex">
      {submitLoading && <CircularLoader isPoistionAbsolute zIndex={3} />}
      <div className="width-50-per">
        <div className={classes.leftContent}>
          <div className={classes.colorBg} />
          <div className={classes.information}>
            <h1 className={classes.animatedText}>Alpaago Snack Universe</h1>
            <span>
              We have created a huge database of snacks from vendors with high
              importance to TASTE and HYGIENE. We have created this to give you
              the best choice of menu almost every single day at affordable
              costs! Our algorithms use a unique method to identify what your
              community loves and selects snacks tailor-made for you to enjoy.
            </span>
          </div>
        </div>
      </div>
      <div className="width-50-per">
        <div className={classes.rightContent}>
          <div className="details">
            <h1 className="yellow-text mr-0">Welcome!</h1>
            <h2>Create Your Account</h2>

            <div className={classes.inputContainer}>
              <SingleInputField
                content={signUpData.userName}
                value={signUpState.userName}
                getInputData={getSignUpInput}
                errorFields={errorFields}
              />
              <SingleInputField
                content={signUpData.phoneNumber}
                value={signUpState.phoneNumber}
                getInputData={getSignUpInput}
                errorFields={errorFields}
                isEditable={!otp}
              />
              <SingleInputField
                content={signUpData.newAlpin}
                value={signUpState.newAlpin}
                getInputData={getSignUpInput}
                errorFields={errorFields}
                isEditable={!otp}
              />
              {!!otp && <SingleInputField
                content={signUpData.otp}
                value={signUpState.otp}
                getInputData={getSignUpInput}
                errorFields={errorFields}
              />}
            </div>

            <div className="mr-tp-20">
              {phoneNoNot10Digit && <p style={{ color: "red", fontSize: "14px" }}>Phone number must be 10 digits long.</p>}
              {alpinNot4Digit && <p style={{ color: "red", fontSize: "14px" }}>Secret alpin must be 4 digits long.</p>}
              {isInvalidOtp && <p>
                <span style={{ color: "red" }}>Invalid OTP! </span>Please{' '}
                <span
                  onClick={handleOtpSendClick}
                  className="blue-text cursor-pointer"
                >
                  Resend
                </span>{' '}
                the OTP.
              </p>}
              {!!otp ? <Button
                variant="contained"
                sx={{ bgcolor: "#00C853", width: "120px" }}
                onClick={handleSubmitSignUp}
              >
                Submit
              </Button> : <Button
                variant="contained"
                sx={{ bgcolor: "#00C853", width: "120px" }}
                onClick={handleOtpSendClick}
              >
                Send OTP
              </Button>}

              <p>
                Already have an account?{" "}
                <span
                  onClick={() => navigate(routes.USER_LOGIN)}
                  className="blue-text cursor-pointer"
                >
                  Login
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Layout;
