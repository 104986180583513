import React from "react";
import { makeStyles } from "@material-ui/core";

const customStyles = makeStyles({
  root: {
    marginTop: "193px",
    backgroundColor: "#1B2452",
    padding: "150px 0px",
    borderRadius: "24px",
    fontFamily: "Manrope",  
  },
  aboutWrapper: {
    width: "90%",
    maxWidth: "1216px",
    display: "flex",
    marginRight: "auto",
    marginLeft: "auto",
  },
  leftSide: {
    minWidth: "58%",
    display: "flex",
    flexDirection: "column",
  },
  aboutHeader: {
    fontSize: "18px",
    color: "#EAF083",
    fontWeight: "600",
    display: "flex",
    justifyContent: "space-between",
  },
  storeHeader: {
    marginTop: "20px",
    color: "#FFFFFF",
    fontSize: "48px",
    lineHeight: "1.3em",
  },
  leftDetails: {
    marginTop: "75px",
    color: "rgba(255, 255, 255, 0.75)",
    fontWeight: "600",
    fontSize: "18px",
    backgroundColor: "rgb(27, 36, 82)",
    fontFamily: "Manrope",  
  },
  righDetails: {
    maxWidth: "40%",
    marginTop: "55px",
    fontWeight: "600",
    fontSize: "18px",
    backgroundColor: "rgb(27, 36, 82)",
    color: "rgba(255, 255, 255, 0.75)",
    fontFamily: "Manrope",  
  },
  '@media (max-width: 390px)': {
    root: {
      padding: "50px 10px",
      marginTop: "100px",
    },
    aboutWrapper: {
      flexDirection: "column",
    },
    leftSide: {
      minWidth: "100%",
      marginBottom: "20px",
    },
    aboutHeader: {
      fontSize: "16px",
    },
    storeHeader: {
      fontSize: "32px",
      lineHeight: "1.2em",
    },
    leftDetails: {
      marginTop: "30px",
      fontSize: "16px",
    },
    righDetails: {
      maxWidth: "100%",
      fontSize: "16px",
    },
  },
});

const About = () => {
  const classes = customStyles();

  return (
    <div className={classes.root}>
      <div className={classes.aboutWrapper}>
        <div className={classes.leftSide}>
          <div className={classes.aboutHeader}>About AlpaaGo</div>
          <div className={classes.storeHeader}>
            A store that is not a store!
          </div>
          <div className={classes.leftDetails}>
            &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;At the store,
            <ul>
              <li>
                Enter your<strong> Al-Pin,</strong>
              </li>
              <li>Pick your snack/book and GO!&nbsp;</li>
            </ul>
            &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;It is really that simple. All in
            less than 10 seconds, typically.&nbsp;&nbsp;
          </div>
        </div>
        <div className={classes.righDetails}>
          Leveraging the power of AI and computer vision, we are building a
          network of stores for snacks, stationery and books that develops a
          tailor-made experience for you and your fellow community members.
          <br />• We know you love to choose a different experience every day,
          we got you covered with{" "}
          <span className="text-decoration-underline">
            <strong>different snacks almost every day</strong>.
          </span>
          <br />• We know you hate spending time for checkout, we got you
          covered with{" "}
          <span className="text-decoration-underline">
            <strong>Al-pin login method.</strong>
          </span>
          <br />• We know you love budgeting your needs, we got you covered with
          <u>
            <b>Alpaago Wallet</b>
          </u>
          , so you never have to spend extra from your savings.
        </div>
      </div>
    </div>
  );
};

export default About;
