import React from "react";
import { makeStyles } from "@material-ui/core";
// import alpaagoIcon from "../../assets/images/aplaago.png";
import alpaagoIcon from "../../assets/Images/aplaago.png";
import { Link, useNavigate } from "react-router-dom";
import { RootNavTabs } from "../../data/RootNavTabs";
import { routes } from "../../routing/routes";

const customStyles = makeStyles({
  root: {
    maxWidth: "100vw",
    width: "100%",
    position: "sticky",
    top: "0",
    transition: "all .4s ease",
    willChange: "unset",
    backgroundClip: "padding-box",
    position: "relative",
    zIndex: 2,
    fontFamily: "Manrope",
  },
  navWrapper: {
    padding: "0 70px",
    display: "flex",
  },
  iconWrapper: {
    margin: "28px 0px",
    height: "28px",
    position: "relative",
  },
  alpaagoIcon: {
    position: "absolute",
    top: "0",
    bottom: "0",
    left: "0",
    height: "150px",
    right: "0",
    transition: "all .2s ease",
    height: "94px",
  },
  navBar: {
    display: "flex",
    flex: "1",
    justifyContent: "space-between",
    marginLeft: "140px",
  },
  navLinks: {
    display: "flex",
    flex: "1",
    justifyContent: "flex-start",
  },
  tabUL: {
    display: "flex",
    listStyle: "none",
    margin: "0",
    padding: "0",
    boxSizing: "border-box",
    gap: "42px",
  },
  tabLI: {
    alignItems: "center",
    display: "flex",
    listStyle: "none",
    margin: "0",
    padding: "0",
  },
  tabA: {
    transition: "all .2s ease",
    display: "block",
    cursor: "pointer",
    textDecoration: "none",
    color: "rgb(39, 19, 64)",
    fontWeight: "600",
    fontFamily: "Manrope",
  },
  navBtn: {
    alignItems: "center",
    display: "flex",
  },
  // Media Query for iPhone 13 Pro
  '@media (max-width: 390px)': {
    navWrapper: {
        padding: "0 10px",
        flexDirection: "column",
    },
    navBar: {
      marginTop:"10px",
        marginLeft: "10px",
        marginBottom:"10px"
    },
    tabUL: {
        display: "flex",
        gap: "10px", 
        flexWrap: "wrap", 
    },
    tabLI: {
        flex: "1 1 auto", // Allow tabs to grow and shrink
        maxWidth: "100%", // Allow full width on smaller screens
    },
    alpaagoIcon: {
        height: "80px",
        marginTop:"-20px",
        marginLeft:"10px"
    },
    tabA: {
        fontSize: "12px", 
    },
    navBtn: {
        fontSize: "12px",
        marginRight: "-5px",
    }
},

});
const RootTopBar = () => {
  const navigate = useNavigate();
  const classes = customStyles();

  return (
    <div className={classes.root}>
      <div className={classes.navWrapper}>
        <div className={classes.iconWrapper}>
          <img
            className={classes.alpaagoIcon}
            src={alpaagoIcon}
            alt="Description of the image"
          />
        </div>
        <div className={classes.navBar}>
          <div className={classes.navLinks}>
            <ul className={classes.tabUL}>
              {RootNavTabs.map((tab) => (
                <li key={tab.id} className={classes.tabLI}>
                  <Link to={tab.tabUrl} className={classes.tabA}>
                    <span>{tab.label}</span>
                  </Link>
                </li>
              ))}
            </ul>
          </div>
          <div className={classes.navBtn}>
            <div
              className={"purpleBtn"}
              onClick={() => {
                navigate(routes.SIGN_UP);
              }}
            >
              Sign Up
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RootTopBar;
