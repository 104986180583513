import React from "react";
import { Grid, makeStyles } from "@material-ui/core";
import PropTypes from "prop-types";

const customStyle = makeStyles({
  formGroup: {
    display: "flex",
    flexDirection: "column",
    gap: "10px",
  },
  formLabel: {
    fontSize: "16px",
    fontWeight: "bold",
  },
  formInput: {
    padding: "16px",
    border: "1px solid #ccc",
    borderRadius: "4px",
    fontSize: "18px",
  },
  '@media (max-width: 390px)': {
    formLabel: {
      fontSize: "10px", 
    },
    formInput: {
      padding: "10px",    
      fontSize: "10px",   
    },
    formGroup: {
      gap: "5px",         
    },
  },
});

/**
 * @typedef {Object} TwoInputFieldProps
 * @property {Object} content - The configuration for the first input field.
 * @property {string} content.label - The label for the first input field.
 * @property {string} content.type - The type of the first input field (e.g., text, number).
 * @property {string} content.placeholder - The placeholder text for the first input field.
 * @property {string} content.value - The value of the first input field.
 * @property {string} content.name - The name attribute for the first input field.
 * @property {boolean} isEditable - Whether the first input field is editable or not.
 * @property {Object} secondContent - The configuration for the second input field.
 * @property {string} secondContent.label - The label for the second input field.
 * @property {string} secondContent.type - The type of the second input field (e.g., text, number).
 * @property {string} secondContent.placeholder - The placeholder text for the second input field.
 * @property {string} secondContent.value - The value of the second input field.
 * @property {string} secondContent.name - The name attribute for the second input field.
 * @property {boolean} isSecondEditable - Whether the second input field is editable or not.
 * @property {function} getInputData - The function to handle input changes and receive data.
 */

const TwoInputField = (props) => {
  const {
    content,
    secondContent,
    value,
    isEditable,
    secondValue,
    isSecondEditable,
    getInputData,
    errorFields,
  } = props;
  const classes = customStyle();

  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <div className={classes.formGroup}>
          <span className={classes.formLabel}>
            {content.label} :{" "}
            {content.hasOwnProperty("isRequired") && content?.isRequired && (
              <span style={{ color: "red" }}>*</span>
            )}{" "}
            {errorFields && errorFields.includes(content.key) && (
              <span style={{ color: "red", fontSize: "14px" }}>Required</span>
            )}
          </span>
          <input
            className={`${classes.formInput} ${
              errorFields && errorFields.includes(content.key)
                ? "error-input-color"
                : ""
            }`}
            type={content.type}
            placeholder={content.placeHolder}
            value={value}
            name={content.key}
            onChange={getInputData}
            disabled={!isEditable}
          />
        </div>
      </Grid>
      <Grid item xs={6}>
        <div className={classes.formGroup}>
          <span className={classes.formLabel}>
            {secondContent.label} :{" "}
            {secondContent.hasOwnProperty("isRequired") &&
              secondContent.isRequired && (
                <span style={{ color: "red" }}>*</span>
              )}{" "}
            {errorFields && errorFields.includes(secondContent.key) && (
              <span style={{ color: "red", fontSize: "14px" }}>Required</span>
            )}
          </span>
          <input
            className={`${classes.formInput} ${
              errorFields && errorFields.includes(secondContent.key)
                ? "error-input-color"
                : ""
            }`}
            type={secondContent.type}
            placeholder={secondContent.placeHolder}
            value={secondValue}
            name={secondContent.key}
            onChange={getInputData}
            disabled={!isSecondEditable}
          />
        </div>
      </Grid>
    </Grid>
  );
};

TwoInputField.defaultProps = {
  content: { label: "", type: "text", placeholder: "", name: "" },
  value: "",
  isEditable: true,
  secondContent: { label: "", type: "text", placeholder: "", name: "" },
  secondValue: "",
  isSecondEditable: true,
  getInputData: () => {},
};

// TwoInputField.propTypes = {
//   content: PropTypes.shape({
//     label: PropTypes.string.isRequired,
//     type: PropTypes.string,
//     placeholder: PropTypes.string.isRequired,
//     name: PropTypes.string.isRequired,
//   }),
//   isEditable: PropTypes.bool,
//   secondContent: PropTypes.shape({
//     label: PropTypes.string.isRequired,
//     type: PropTypes.string,
//     placeholder: PropTypes.string.isRequired,
//     name: PropTypes.string.isRequired,
//   }),
//   isSecondEditable: PropTypes.bool,
//   value: PropTypes.string.isRequired,
//   secondValue: PropTypes.string.isRequired,
//   getInputData: PropTypes.func.isRequired,
// };

export default TwoInputField;
