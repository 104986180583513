import React, { useEffect, useRef } from "react";
import Layout from "./Layout";
import useTableFunction from "../../../hooks/useTableFunction";
import { COLLECTION, SESSION } from "../../../data/constant";
import { db } from "../../../config/firebase";
import { doc, getDoc } from "firebase/firestore";

const VendorPods = () => {
  const dynamicConditionCB = async () => {
    let vendor = "";
    // const sessionId = localStorage.getItem(SESSION.VENDOR_SESSION_ID);
    // const docRef = doc(db, COLLECTION.VENDOR_SESSION, sessionId);
    // const docSnap = await getDoc(docRef);
    // if (docSnap.exists()) {
    //   const data = docSnap.data();
    //   console.log("vendor",data)
    //   vendor = data.vendorId;
    // }
    const sessionId = localStorage.getItem(SESSION.VENDOR_SESSION_ID);
        
    if (!sessionId) {
      throw new Error("Vendor session ID is not found in local storage.");
    }

     // Fetch vendor session data
     const vendorSessionDocRef = doc(db, COLLECTION.VENDOR_SESSION, sessionId);
     const vendorSessionDocSnap = await getDoc(vendorSessionDocRef);
     if (!vendorSessionDocSnap.exists()) {
       throw new Error("Vendor session document not found");
     }
     const vendorData = vendorSessionDocSnap.data();
     const vendorDetailsDocRef = doc(db, COLLECTION.VENDOR,vendorData.vendorId );
     const vendorDetailsDocSnap = await getDoc(vendorDetailsDocRef);
     const vendordata  = vendorDetailsDocSnap.data()
     vendor = vendordata.vendorId;
     //console.log("vendordata",vendor)


    return {
      column: "vendor",
      operator: "==",
      query: vendor,
    };
  };
  

  const {
    tableData,
    isTableLoading,
    currentPage,
    totalLength,
    handlePageChange,
    handlePageSize,
    handleSearchQuery,
    handleTableSorting,
    refetchTableData,
  } = useTableFunction({
    collectionPath: COLLECTION.POD,
   dynamicCondition: dynamicConditionCB,
  });

  return (
    <Layout
      vendorPodData={tableData}
      isTableLoading={isTableLoading}
      currentPage={currentPage}
      totalLength={totalLength}
      handlePageChange={handlePageChange}
      handlePageSize={handlePageSize}
      handleSearchQuery={handleSearchQuery}
      handleTableSorting={handleTableSorting}
    />
  );
};

export default VendorPods;
