import React from "react";
import { makeStyles } from "@material-ui/core";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import PhoneIcon from "@mui/icons-material/Phone";
import LocationOnIcon from "@mui/icons-material/LocationOn";

const customStyles = makeStyles({
  root: {
    borderRadius: "24px 24px ",
    backgroundColor: "#1B2452",
    fontFamily: "Manrope",  
  },
  footer: {
    width: "90%",
    maxWidth: "1216px",
    display: "flex",
    flexDirection: "column",
    marginRight: "auto",
    marginLeft: "auto",
    paddingTop: "100px",
    paddingBottom: "50px",
    gap: "100px",
  },
  contactWrapper: {
    alignItems: "center",
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
  },
  contactLeft: {
    display: "flex",
    flexDirection: "column",
    width: "60%",
    gap: "10px",
  },
  contactRight: {
    display: "flex",
    flexDirection: "column",
    width: "40%",
    gap: "14px",
  },
  contactRightItem: {
    gap: "15px",
    color: "white",
    display: "flex",
    flexWrap: "inherit",
    fontWeight: "600",
    alignItems: "center",
  },
  copyRights: {
    textAlign: "right",
    color: "#FFFFFF5C",
    fontSize: "16px",
    fontWeight: "500",
  },
   // Media query for iPhone 13 Pro
   '@media (max-width: 390px)': {
    footer: {
      paddingTop: "50px", // Reduced padding for mobile
      paddingBottom: "30px", // Reduced padding for mobile
      gap: "50px", // Reduced gap for mobile
    },
    contactWrapper: {
      flexDirection: "column", // Stack items on mobile
    },
    contactLeft: {
      width: "100%", // Full width on mobile
      gap: "5px", // Reduced gap
    },
    contactRight: {
      width: "100%", // Full width on mobile
      gap: "10px", // Reduced gap
    },
    contactRightItem: {
      gap: "10px", // Reduced gap
    },
    copyRights: {
      textAlign: "center", // Center text for better visibility
      fontSize: "14px", // Smaller font size
    },
  },
});
const RootFooter = () => {
  const classes = customStyles();
  return (
    <div className={classes.root}>
      <div className={classes.footer}>
        <div className={classes.contactWrapper}>
          <div className={classes.contactLeft}>
            <span className="white-text font-size-36 font-weight-600">
              Contact information
            </span>
            <span className="grey-text">Call us anytime – +91 73974 60454</span>
          </div>
          <div className={classes.contactRight}>
            <div className={classes.contactRightItem}>
              <EmailOutlinedIcon fontSize="small" color="primary" />{" "}
              contact@alpaago.com
            </div>
            <div className={classes.contactRightItem}>
              <PhoneIcon fontSize="small" color="primary" /> +91 73974 60454
            </div>
            <div className={classes.contactRightItem}>
              <LocationOnIcon fontSize="small" color="primary" /> No 1104, 1st
              Main Rd, Stage I, Kengeri Satellite Town, Bengaluru, Karnataka
              560060
            </div>
          </div>
        </div>
        <div className={classes.copyRights}>
          Copyright: © 2024 Alpaago All Rights Reserved.
        </div>
      </div>
    </div>
  );
};

export default RootFooter;
