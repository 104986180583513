import React from "react";

import Layout from "./Layout";
import useTableFunction from "../../../hooks/useTableFunction";
import { campusData, campusTableHeading } from "../../../assets/lib/campus";
import { COLLECTION } from "../../../data/constant";

export default function CampusManagement() {
  const {
    tableData,
    isTableLoading,
    currentPage,
    totalLength,
    handlePageChange,
    handlePageSize,
    handleSearchQuery,
    handleTableSorting,
  } = useTableFunction({
    collectionPath: COLLECTION.CAMPUS,
    defaultOrderBy: campusData.campusId.key,
  });

  return (
    <>
      <Layout
        campusTableHeading={campusTableHeading}
        campusTableData={tableData}
        isTableLoading={isTableLoading}
        currentPage={currentPage}
        totalLength={totalLength}
        handlePageChange={handlePageChange}
        handlePageSize={handlePageSize}
        handleSearchQuery={handleSearchQuery}
        handleTableSorting={handleTableSorting}
      />
    </>
  );
}
