import actionTypes from "../constants/constants";

export const initialState = {
  podDetails: {
    isLoading: true,
    error: "",
    data: null,
  },
};

export function updatePodState(state = initialState, action) {
  switch (action.type) {
    case actionTypes.FETCH_POD_REQUEST:
      return {
        ...state,
        podDetails: {
          ...state.podDetails,
          isLoading: true,
          error: "",
        },
      };
    case actionTypes.FETCH_POD_SUCCESS:
      return {
        ...state,
        podDetails: {
          ...state.podDetails,
          isLoading: false,
          data: action.payload,
        },
      };
    case actionTypes.FETCH_POD_ERROR:
      return {
        ...state,
        podDetails: {
          ...state.podDetails,
          isLoading: false,
          error: action.payload,
        },
      };

    default:
      return state;
  }
}
