import React, { useState, useEffect } from "react";
import { doc, getDoc, setDoc } from "firebase/firestore";
import { db } from "../../../config/firebase";
import Layout from "./Layout";
import { COLLECTION, SESSION } from "../../../data/constant";

const UserProfile = () => {
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [userId, setUserId] = useState(null);
  const [dataChanged, setDataChanged] = useState(false);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const sessionId = localStorage.getItem(SESSION.USER_SESSION_ID);
        if (!sessionId) {
          throw new Error("User session ID is not found in local storage.");
        }

        // Fetch user session data
        const userSessionDocRef = doc(db, COLLECTION.USER_SESSION, sessionId);
        const userSessionDocSnap = await getDoc(userSessionDocRef);

        if (!userSessionDocSnap.exists()) {
          throw new Error("User session document not found");
        }

        const userData = userSessionDocSnap.data();
        setUserId(userData.userID);

        const userDetailsDocRef = doc(db, COLLECTION.USER, userData.userID);
        const userDetailsDocSnap = await getDoc(userDetailsDocRef);

        if (!userDetailsDocSnap.exists()) {
          throw new Error("User details document not found");
        }

        setUserData(userDetailsDocSnap.data());
        setLoading(false);
      } catch (error) {
        console.error("Error fetching user data:", error);
        setLoading(false);
      }
    };

    fetchUserData();
  }, [dataChanged]); // Trigger fetchUserData when dataChanged state changes

  const handleSaveChanges = async (updatedUserData) => {
    try {
      // Update user data in Firestore
      const userDocRef = doc(db, COLLECTION.USER, userId);
      await setDoc(userDocRef, updatedUserData);

      // Update userData state with the updated data
      setUserData(updatedUserData);

      // Trigger a data change to refetch user data and update the component
      setDataChanged(!dataChanged);
    } catch (error) {
      console.error("Error saving user data:", error);
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!userData) {
    return <div>User data not found</div>;
  }

  return <Layout userData={userData} userId={userId} loading={loading} onSaveChanges={handleSaveChanges} />;
};

export default UserProfile;
