const walletData = {
  rechargeAmount: {
    key: "rechargeAmount",
    label: "Recharge Amount",
    placeHolder: "100",
    type: "number",
    isRequired: true,
  },
};

export { walletData };
