import React from "react";
import Layout from "./Layout";
import { useState } from "react";
import useTableFunction from "../../../hooks/useTableFunction";
import { COLLECTION, COLLECTION_COUNTER } from "../../../data/constant";
import { podMachineData } from "../../../assets/lib/pod";
import {
  createInitialForm,
  fetchLatestId,
  updateLatestId,
  validateRequiredField,
} from "../../../common/common";
import { addDoc, collection } from "firebase/firestore";
import { db } from "../../../config/firebase";

const ManagePods = () => {
  const [podMachineState, setPodMachineState] = useState(
    createInitialForm(podMachineData)
  );
  const [errorFields, setErrorFields] = useState([]);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [isFetchingPodMachineNum, setIsFetchingPodMachineNum] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [errorOnFetch, setErrorOnFetch] = useState(false);

  const getPodMachineInput = (event) => {
    const name = event.target.name;
    let value = event.target.value;

    const inputType = event.target.type;
    if (value && inputType === "number") {
      value = parseInt(value);
    }
    if (errorFields.includes(name) && ![undefined, null, ""].includes(value)) {
      setErrorFields((prevState) => prevState.filter((key) => key !== name));
    }
    setPodMachineState((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleSubmitModal = async () => {
    const error = validateRequiredField(podMachineData, podMachineState);
    setErrorFields(error);
    setSubmitLoading(true);
    if (error.length === 0) {
      try {
        // Add new data
        const podMachineCollectionRef = collection(db, COLLECTION.POD_TYPE);
        await addDoc(podMachineCollectionRef, podMachineState);
        await updateLatestId(
          COLLECTION_COUNTER.POD_TYPE,
          podMachineState.podType
        );

        setIsModalOpen(false);
        refetchTableData();
        setPodMachineState(createInitialForm(podMachineData));
      } catch (error) {
        console.log("error", error);
        setErrorOnFetch(true);
      }
    }
    setSubmitLoading(false);
  };

  const fetchPodMachineNumber = async () => {
    setIsFetchingPodMachineNum(true);
    const latestId = await fetchLatestId(COLLECTION_COUNTER.POD_TYPE);
    setPodMachineState((prevState) => ({
      ...prevState,
      [podMachineData.podType.key]: latestId,
    }));
    setIsFetchingPodMachineNum(false);
  };

  const {
    tableData,
    isTableLoading,
    currentPage,
    totalLength,
    handlePageChange,
    handlePageSize,
    handleSearchQuery,
    handleTableSorting,
    refetchTableData,
  } = useTableFunction({
    collectionPath: COLLECTION.POD_TYPE,
    defaultOrderBy: podMachineData.podType.key,
  });

  return (
    <Layout
      podMachineTableData={tableData}
      isTableLoading={isTableLoading}
      currentPage={currentPage}
      totalLength={totalLength}
      handlePageChange={handlePageChange}
      handlePageSize={handlePageSize}
      handleSearchQuery={handleSearchQuery}
      handleTableSorting={handleTableSorting}
      isModalOpen={isModalOpen}
      setIsModalOpen={setIsModalOpen}
      handleSubmitModal={handleSubmitModal}
      podMachineState={podMachineState}
      getPodMachineInput={getPodMachineInput}
      errorFields={errorFields}
      fetchPodMachineNumber={fetchPodMachineNumber}
      isModalLoading={isFetchingPodMachineNum || submitLoading}
    />
  );
};

export default ManagePods;
