
import React from "react";
import { makeStyles } from "@material-ui/core";
import SingleInputField from "../../../components/SingleInputField";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    position: "relative",
  },
  container: {
    display: "flex",
    padding: "20px",
    border: "1px solid #ccc",
    borderRadius: "8px",
    marginBottom: "20px",
  },
  leftside: {
    display: "flex",
    flexDirection: "column",
    width: "40%",
    marginLeft: "20px",
    marginRight: "8%",
  },
  rightside: {
    display: "flex",
    flexDirection: "column",
    width:"40%",
  },
}));

const Layout = ({ vendorData }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        

        <div className={classes.leftside}>
         
          <SingleInputField 
            content={{ label: "GST Number", isRequired: true, key: "GSTNumber" }}
            value={vendorData.GSTNumber}
          />
          <SingleInputField 
            content={{ label: "Alpin", key: "alpin" }}
            value={vendorData.alpin}
          />
          <SingleInputField 
            content={{ label: "Commission", key: "commission" }}
            value={vendorData.commission}
          />
          <SingleInputField 
            content={{ label: "Contact Person Name", key: "contactPersonName" }}
            value={vendorData.contactPersonName}
          />
          <SingleInputField 
            content={{ label: "Contact Person Phone", key: "contactPersonPhone" }}
            value={vendorData.contactPersonPhone}
          />
          <SingleInputField 
            content={{ label: "Inventory", key: "inventory" }}
            value={Object.entries(vendorData.inventory).map(([key, value]) => `${key}: ${value}`).join(', ')}
          />
          <SingleInputField 
            content={{ label: "Location", key: "location" }}
            value={vendorData.location}
          />
          
        </div>
    
        <div className={classes.rightside}>
        <SingleInputField 
            content={{ label: "Number of Pods", key: "noOfPods" }}
            value={vendorData.noOfPods}
          />
          <SingleInputField 
            content={{ label: "Phone Number", key: "phoneNumber" }}
            value={vendorData.phoneNumber}
          />
          <SingleInputField 
            content={{ label: "Pods for Vendor", key: "podsForVendor" }}
            value={vendorData.podsForVendor.join(', ')}
          />
          <SingleInputField 
            content={{ label: "Rent", key: "rent" }}
            value={vendorData.rent}
          />
          <SingleInputField 
            content={{ label: "Vendor Address", key: "vendorAddress" }}
            value={vendorData.vendorAddress}
          />
          <SingleInputField 
            content={{ label: "Vendor ID", key: "vendorId" }}
            value={vendorData.vendorId}
          />
          <SingleInputField 
            content={{ label: "Vendor Name", key: "vendorName" }}
            value={vendorData.vendorName}
          />
          <SingleInputField 
            content={{ label: "Zone", key: "zone" }}
            value={vendorData.zone}
          />
        </div>
      </div>
    </div>
  );
};

export default Layout;
