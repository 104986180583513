const vendorPodTableHeading = [
  {
    key: "podNumber",
    label: "POD Number",
  },
  {
    key: "status",
    label: "Status",
    format: (value) => (value ? "Active" : "Not Active"),
  },
  {
    key: "totalPodTransactions",
    label: "Total Pod Transactions",
  },
  {
    key: "inventory",
    label: "Current Inventory",
    format: (value) => {
      let str = "";
      for (const vendor in value) {
        if (value.hasOwnProperty(vendor)) {
          const items = value[vendor];
          for (const item in items) {
            if (items.hasOwnProperty(item)) {
              str = str + " " + item;
            }
          }
        }
      }

      return str;
    },
  },
  {
    key: "campus",
    label: "Campus",
  },
];

export { vendorPodTableHeading };
