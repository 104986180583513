import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button, Typography } from "@mui/material";
import { SingleInputField } from "../../../components";
import { walletData } from "../../../assets/lib/wallet";
import { CircularLoader } from "../../../components/widgets/CircularLoader";

const customStyle = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4),
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: theme.spacing(3),
  },
  header: {
    marginTop: theme.spacing(2), // Move the header to the top
    marginBottom: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.primary.main,
  },
  inputFieldContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
    marginTop: "60px",
  },
  inputField: {
    width: "100%",
  },
  button: {
    backgroundColor: theme.palette.success.main,
    width: "120px",
    color: "white",
    "&:hover": {
      backgroundColor: theme.palette.success.dark,
    },
  },
}));

const Layout = (props) => {
  const { walletAmount, handle, getWalletInput, walletState, rechargeLoader } =
    props;
  const classes = customStyle();

  return (
    <div elevation={3} className={classes.root}>
      {rechargeLoader && <CircularLoader isPoistionAbsolute />}
      <Typography variant="h4" className={classes.header}>
        Your Wallet: ₹{walletAmount}
      </Typography>

      <div className={classes.inputFieldContainer}>
        <SingleInputField
          content={walletData.rechargeAmount}
          value={walletState.rechargeAmount}
          getInputData={getWalletInput}
          errorFields={[]}
          className={classes.inputField}
        />
      </div>

      <Button variant="contained" className={classes.button} onClick={handle}>
        Recharge
      </Button>
    </div>
  );
};

export default Layout;
