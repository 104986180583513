import React, { useEffect, useState } from "react";
import { Button, makeStyles, Grid } from "@material-ui/core";
import { Menu, MenuItem, TextField } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

import { productData } from "../../../assets/lib/product";
import {
  CustomModal,
  SingleInputField,
  TwoInputField,
} from "../../../components";

const customStyle = makeStyles({
  formContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "20px",
  },
  formGroup: {
    display: "flex",
    flexDirection: "column",
    gap: "10px",
  },
  formLabel: {
    fontSize: "16px",
    fontWeight: "bold",
  },
});

const ProductsModal = (props) => {
  const {
    setIsModalOpen,
    isModalOpen,
    handleSubmitModal,
    prodState,
    getProductInput,
    errorFields,
    loadingModal,
    campusData,
    campusMenu,
    setCampusMenu,
    vendorData,
    vendorMenu,
    setVendorMenu,
    gid
  } = props;
  const classes = customStyle();
  const [anchorElCampus, setAnchorElCampus] = useState(null);
  const [anchorElVendor, setAnchorElVendor] = useState(null);

  const onChangeMenuItemCampus = (item) => {
    setCampusMenu(item);
    setAnchorElCampus(null);
  };

  const onChangeMenuItemVendor = (item) => {
    setVendorMenu(item);
    setAnchorElVendor(null);
  };

  useEffect(() => {
    if (campusData.length > 0) {
      setCampusMenu(campusData[0]);
    }
  }, [campusData]);

  useEffect(() => {
    if (vendorData.length > 0) {
      setVendorMenu(vendorData[0]);
    }
  }, [vendorData]);

  return (
    <CustomModal
      heading={"Add Product"}
      open={isModalOpen}
      onClose={() => setIsModalOpen(false)}
      onSubmit={handleSubmitModal}
      showLoading={loadingModal}
    >
      <div className={classes.formContainer}>
        <TwoInputField
          content={productData.productId}
          value={gid}
          isEditable={false}
          secondContent={productData.productName}
          secondValue={prodState.productName}
          errorFields={errorFields}
          getInputData={getProductInput}
          
        />
          <TwoInputField
          content={productData.unitPrice}
          value={prodState.unitPrice}
          secondContent={productData.expiry}
          secondValue={prodState.expiry}
          errorFields={errorFields}
          getInputData={getProductInput}
        />
      </div>
    </CustomModal>
  );
};

export default ProductsModal;
