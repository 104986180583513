import React from "react";
import { IconButton, makeStyles } from "@material-ui/core";
import MenuRoundedIcon from "@mui/icons-material/MenuRounded";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import alpaagoIcon from "../../assets/Images/aplaago.png";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import NotificationsIcon from "@mui/icons-material/Notifications";
import SettingsIcon from "@mui/icons-material/Settings";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import "./TopBar.css";

const customStyles = makeStyles({
  topBar: {
    width: "100%",
    height: "60px",
    // boxShadow: "0 2px 5px rgba(0, 0, 0, 0.2)",
    display: "flex",
    justifyContent: "space-between",
  },
  menuBar: {
    display: "flex",
    gap: "20px",
  },
  alpaagoLogo: {
    display: "flex",
    alignItems: "center",
    gap: "10px",
    fontSize: "28px",
    fontWeight: "700",
    color: "#271340",
  },
  alpaagoIcon: {
    width: "40px",
    height: "40px",
  },
  actionButtons: {
    display: "flex",
    alignItems: "center",
  },
});

const TopBar = (props) => {
  const { fullSideBar, setFullSideBar, handleLogout } = props;
  const classes = customStyles();
  return (
    <header className={classes.topBar}>
      <div className={classes.menuBar}>
        <IconButton
          className="height-fit-content"
          onClick={() => setFullSideBar((prevState) => !prevState)}
        >
          {fullSideBar ? (
            <MenuOpenIcon sx={{ fontSize: 40, color: "#5E6E82" }} />
          ) : (
            <MenuRoundedIcon sx={{ fontSize: 40, color: "#5E6E82" }} />
          )}
        </IconButton>
        <div className={classes.alpaagoLogo}>
          <img
            className={classes.alpaagoIcon}
            src={alpaagoIcon}
            alt="Description of the image"
          />
          <span>AlpaaGO</span>
        </div>
      </div>
      <div className={classes.actionButtons}>
        {/* <IconButton style={{ padding: "10px" }} onClick={() => {}}>
          <SettingsIcon sx={{ fontSize: 26, color: "#5E6E82" }} />
        </IconButton>
        <IconButton style={{ padding: "10px" }} onClick={() => {}}>
          <NotificationsIcon sx={{ fontSize: 26, color: "#5E6E82" }} />
        </IconButton>
        <IconButton style={{ padding: "10px" }} onClick={() => {}}>
          <AccountCircleIcon sx={{ fontSize: 40, color: "#5E6E82" }} />
        </IconButton> */}
        <IconButton className={classes.logoutButton} onClick={handleLogout}>
          <span className="font-size-18 mr-rt-6">Logout</span>
          <ExitToAppIcon sx={{ fontSize: 26, color: "#5E6E82" }} />
        </IconButton>
      </div>
    </header>
  );
};

export default TopBar;
