import actionTypes from "../constants/constants";

export const initialState = {
  userDetails: {
    isLoading: true,
    error: "",
    data: null,
  },
};

export function updateUserState(state = initialState, action) {
  switch (action.type) {
    case actionTypes.FETCH_USER_REQUEST:
      return {
        ...state,
        userDetails: {
          ...state.userDetails,
          isLoading: true,
          error: "",
        },
      };
    case actionTypes.FETCH_USER_SUCCESS:
      return {
        ...state,
        userDetails: {
          ...state.userDetails,
          isLoading: false,
          data: action.payload,
        },
      };
    case actionTypes.FETCH_USER_ERROR:
      return {
        ...state,
        userDetails: {
          ...state.userDetails,
          isLoading: false,
          error: action.payload,
        },
      };

    default:
      return state;
  }
}
