import React, { useRef, useState } from "react";
import Layout from "./Layout";
import useTableFunction from "../../../hooks/useTableFunction";
import { COLLECTION } from "../../../data/constant";
import { getDownloadURL, listAll, ref } from "firebase/storage";
import { db, storage } from "../../../config/firebase";
import { useEffect } from "react";
import { transactionTableHeading } from "../../../assets/lib/cartingTool";
import { doc,where,  getDoc, setDoc } from "firebase/firestore";
import { collection, query,addDoc,getDocs ,updateDoc} from "firebase/firestore";

import moment from "moment";

const CartingTool =  () => {

  const dynamicConditionCB = async() =>{
     return{
     column: "authStatus",
     operator: "==",
     query: "AUTH1",
  
     }

    
 };


  const {
    tableData,
    isTableLoading,
    currentPage,
    totalLength,
    handlePageChange,
    handlePageSize,
    handleSearchQuery,
    handleTableSorting,
    handleSearchDate,
  } = useTableFunction({
    collectionPath: COLLECTION.TRANSACTIONS,
   dynamicCondition:dynamicConditionCB,
   //defaultOrderBy: "transactionTime",
  });

  


  const [images, setImages] = useState("");
  const [updateTransId, setUpdateTransId] = useState("");
  const [menuItems, setMenuItems] = useState([]);
  const [currentImagesfirst, setCurrentImagesfirst] = useState(null);
  const [currentImages, setCurrentImages] = useState(null);
  const [cameraGroup, setCameraGroup] = useState([]);
  const [isModalLoading, setIsModalLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [addedItems, setAddedItems] = useState([]);
  const [validationErrors, setValidationErrors] = useState([]);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [fromDate, setFromDate] = useState(moment());
  const [toDate, setToDate] = useState(moment());
  const isCloseCalledRef = useRef(false);
  const updateTransIdRef = useRef(0);
  const [MachineID,setMachineID]=useState("");
  const groupAndSortItems = (items) => {
    const validItems = items.filter((item) => typeof item === "string");
    // Sort items based on the first number in their names
    const sortedItems = validItems.sort((a, b) => {
      const numA = parseInt(a.split("_")[0]);
      const numB = parseInt(b.split("_")[0]);
      return numA - numB;
    });
    // Group items based on the second part of their names
    const groupedItems = {};
    sortedItems.forEach((item) => {
      const parts = item.split("_");
      const key = parts[1]; // Assuming the second part is the key for grouping
      if (!groupedItems[key]) {
        groupedItems[key] = [];
      }
      groupedItems[key].push(item);
    });
    const resultArray = Object.values(groupedItems);
    setImages(resultArray)
    return resultArray;
  };

  const displayImageSequentiallyfirst = async (
    startIndex,
    group,
    transactionRef,
    cameraNum
  ) => {
      if (isCloseCalledRef.current) {
        return;
      }
      const imageName = group[startIndex];
      const imageUrl = await getDownloadURL(ref(transactionRef, imageName));
      const image = {
        [cameraNum]: { imageUrl, imageName },
      };
      setCurrentImagesfirst((prevState) => ({
        ...prevState,
        ...image,
      }));
      if (isModalLoading) {
        setIsModalLoading(false);
      }
       await new Promise((resolve) => setTimeout(resolve,10));
  };

  const displayImageSequentially = async (
    startIndex,
    group,
    transactionRef,
    cameraNum
  ) => {
    for (let index = startIndex; index < group.length; index++) {
      if (isCloseCalledRef.current) {
        return;
      }
      const imageName = group[index];
      const imageUrl = await getDownloadURL(ref(transactionRef, imageName));
      const image = {
        [cameraNum]: { imageUrl, imageName },
      };
      setCurrentImages((prevState) => ({
        ...prevState,
        ...image,
      }));
      if (isModalLoading) {
        setIsModalLoading(false);
      }
       await new Promise((resolve) => setTimeout(resolve,10));
    }
  };

  const fetchImages = async (updateTrasactionData) => {
    isCloseCalledRef.current = false;
    if (updateTrasactionData.id) {
      setIsModalLoading(true);
      updateTransIdRef.current = updateTrasactionData.id;
      const transactionRef = ref(
        storage,
        `transactions/${updateTrasactionData.id}`
      );
      try {
        // Fetch the list of images
        const list = await listAll(transactionRef);
        const sortedFileNames = list.items.map((itemRef) => itemRef.name);
        const group = groupAndSortItems(sortedFileNames);
        if (group && group.length === 0) {
          setIsModalLoading(false);
          return;
        }
        setCurrentImagesfirst(null)
        setCurrentImages(null); // Reset the current content
        setCameraGroup(group);

        // Fetch the menu items
        const inventoryDocRef = doc(
          db,
          COLLECTION.INVENTORY,
          updateTrasactionData.machineId
        );
        const inventoryDocSnap = await getDoc(inventoryDocRef);
       
        if (inventoryDocSnap.exists()) {
          let allItemsArray = [];
          const inventoryData = inventoryDocSnap.data();
          Object.keys(inventoryData).forEach((item) => {
            allItemsArray.push({
              itemKey: item,
              ...inventoryData[item],
            });
          });
          setMenuItems(allItemsArray);
          
        }
      } catch (error) {
        console.error("Error listing files:", error);
      }
    }
  };

  const fetchCameraImagesfirst = async (cameraGroup) => {
    const updateTransId = updateTransIdRef.current;
    if (updateTransId) {
      const transactionRef = ref(storage, `transactions/${updateTransId}`);
      if (cameraGroup.length > 0) {
        // setCurrentImages(null); // Reset the current
        await Promise.all(
          cameraGroup.map(async (camera, idx) => {
            await displayImageSequentiallyfirst(0, camera, transactionRef, idx);
          })
          
        );
      }
    }
  };

  const fetchCameraImages = async (cameraGroup) => {
    const updateTransId = updateTransIdRef.current;
    if (updateTransId) {
      const transactionRef = ref(storage, `transactions/${updateTransId}`);
      if (cameraGroup.length > 0) {
        // setCurrentImages(null); // Reset the current
        await Promise.all(
          cameraGroup.map(async (camera, idx) => {
            await displayImageSequentially(0, camera, transactionRef, idx);
          })
          
        );
      }
    }
  };


  const onCloseModal = () => {
    isCloseCalledRef.current = true;
    // Inorder to stop the for loop wait till the execution is stoped
    setTimeout(() => {
      updateTransIdRef.current = 0;
      setCurrentImagesfirst(null)
      setCurrentImages(null);
      setIsModalLoading(false);
      setCameraGroup([]);
      setIsModalOpen(false);
    }, 500);
  };

  const onClickDateSearch = () => {
    handleSearchDate(fromDate, toDate, "transactionTime");
  };

  const handleAddItem = () => {
    setAddedItems((prevItems) => [
      ...prevItems,
      {
        id: prevItems[prevItems.length - 1].id + 1,
        selectedItem: menuItems[0],
        quantity: 0,
      },
    ]);
  };

  const handleDeleteItem = (id) => {
    if (addedItems.length > 1) {
      setAddedItems((prevItems) => prevItems.filter((item) => item.id !== id));
    }
    setValidationErrors((prevErrors) => {
      if (prevErrors.some((error) => error.id === id)) {
        return prevErrors.filter((error) => error.id !== id);
      }
      return prevErrors;
    });
  };

  const onChangeValue = (value, id) => {
    setValidationErrors((prevErrors) => {
      if (value > 0 && prevErrors.some((error) => error.id === id)) {
        console.log(
          "prevErrors",
          prevErrors.filter((error) => error.id !== id)
        );
        return prevErrors.filter((error) => error.id !== id);
      }
      return prevErrors;
    });
    setAddedItems((prevItems) => {
      const updatedItems = prevItems.map((item) =>
        item.id === id ? { ...item, quantity: value } : item
      );
      return updatedItems;
    });
  };
  // to fetch amount 
 
  const fetchWalletAmount = async (PHONE) => {
    try {
      const userRef = collection(db, 'Users');
      const userSnapshot = await getDocs(query(userRef, where('phoneNumber', '==', parseInt(PHONE))));
      // console.log(!userSnapshot.empty)
      
      if (!userSnapshot.empty) {
        const userDocSnapshot = userSnapshot.docs[0].data();
        const ans=userDocSnapshot.walletAmount;
        return ans;
        
      } else {
        console.log("User document not found for phone number:", PHONE);
        return null;
      }
    } catch (error) {
      console.error("Error fetching wallet amount:", error);
      return null;
    }
  };
  
  
  
  const handleSubmit = async () => {
  const newValidationErrors = addedItems.filter((item) => item.quantity <= 0);
  setValidationErrors(newValidationErrors);
  const isQuantityValid = newValidationErrors.length === 0;

  try {
    setSubmitLoading(true);
    if (isQuantityValid && updateTransIdRef.current) {
      const documentRef = doc(
        db,
        COLLECTION.TRANSACTIONS,
        updateTransIdRef.current
      );
       console.log(updateTransIdRef.current);
      const itemPrices = {};

      const transactionDocSnap = await getDoc(documentRef);


      const transactionId = updateTransIdRef.current;

      const walletAmount = await fetchWalletAmount((transactionDocSnap.data().phoneNumber));
      console.log("wallet amount",walletAmount)

        await Promise.all(
          addedItems.map(async (item) => {
            const itemKey = item.selectedItem.itemKey;
             console.log("itemkey",itemKey);
            
             const transactionData = transactionDocSnap.data();
             const mid = transactionData.machineId
              console.log(mid)
            const inventoryDocRef = doc(db, COLLECTION.INVENTORY, mid);
            console.log("inventoryDocRef",inventoryDocRef);

            const inventoryDocSnap = await getDoc(inventoryDocRef);
             console.log("inventoryDocSnap",inventoryDocSnap);

            if (inventoryDocSnap.exists()) {
              const itemData = inventoryDocSnap.data();
               console.log("itemData",itemData);
              const selectedItemData = itemData[itemKey];
        
              if (selectedItemData) {
                const pricePerOne = selectedItemData.unitPrice/selectedItemData.quantity
                console.log("unitprice",pricePerOne)
                const unitPrice = pricePerOne;
                const quantityTaken = parseInt(item.quantity);

                 console.log(typeof(unitPrice))
                if (typeof unitPrice === 'number') {
                  // console.log("pikachu")
                  itemPrices[itemKey] = unitPrice;

                  console.log("done")

                  await updateDoc(inventoryDocRef, {
                    [itemKey + '.quantity']: selectedItemData.quantity - quantityTaken
                  });
                  
                  await updateDoc(inventoryDocRef, {
                    [itemKey + '.unitPrice']: selectedItemData.unitPrice - quantityTaken*unitPrice
                  });
                  
                  console.log("transaction doc:",transactionDocSnap.data()
                  )
                  await updateDoc(documentRef, {
                    ['itemsPicked']: selectedItemData.productName
                  });

                  await updateDoc(documentRef, {
                    ['transactionAmount']: quantityTaken*unitPrice
                  });

                  const cartStatus = "Completed"
                  await updateDoc(documentRef, {
                    ['cartingStatus']: cartStatus
                  });

                } else {
                  console.warn("Unit price not found for", itemKey);
                }
              } else {
                console.warn("Data not found for", itemKey);
              }
            } else {
              console.warn("Document not found for", itemKey);
            }
          })
        );
        

      const transactionDetails = {
        itemsPicked: {},
        totalItems: 0,
        totalTransactionamount: 0,
      };

      addedItems.forEach((item) => {
        const itemKey = item.selectedItem.itemKey;
        const quantity = parseInt(item.quantity);
        const unitPrice = itemPrices[itemKey] || 0; 
        // console.log(unitPrice);
        transactionDetails.itemsPicked[itemKey] = quantity;
        transactionDetails.totalItems += quantity;
        transactionDetails.totalTransactionamount += quantity * unitPrice;
         console.log("transc amount",transactionDetails.totalTransactionamount)

      });

      // Set carted time
      transactionDetails.cartedTime = new Date();

      const existingSnapshot = await getDoc(documentRef);
      const existingData = existingSnapshot.data();

      
      const dataToInsert = { ...existingData, transactionDetails };

      await setDoc(documentRef, dataToInsert);

      const paymentHistory=transactionDetails.totalTransactionamount;
      const dataReInsert = { ...existingData, paymentHistory };
      await setDoc(documentRef, dataReInsert);
      // console.log("yeah",paymentHistory);

      // console.log(transactionDocSnap.data().phoneNumber);


      // console.log(walletAmount)

      const walletDeductionsRef = collection(db, COLLECTION.WALLET_DEDUCTIONS);
      const walletDeductionData = {
        Itemspicked: transactionDetails.itemsPicked,
        Podnumber: MachineID, 
        Timestamp: new Date(),
        amountDeducted: transactionDetails.totalTransactionamount,
        transactionid: updateTransIdRef.current,
        phoneNumber:transactionDocSnap.data().phoneNumber,
        Beforeamount:walletAmount,
        Afteramount: walletAmount-transactionDetails.totalTransactionamount
      };
      
      
      await addDoc(walletDeductionsRef, walletDeductionData);
        // update in users collection

        const phoneNumber = transactionDocSnap.data().phoneNumber;
        // console.log(phoneNumber);
        
        const userQuerySnapshot = await getDocs(query(collection(db, COLLECTION.USER), where('phoneNumber', '==', parseInt(phoneNumber))));
        
        if (!userQuerySnapshot.empty) {
        
          const userData = userQuerySnapshot.docs[0].data();
          const userId = userQuerySnapshot.docs[0].id;
          const currentWalletAmount = userData.walletAmount;
        
          const updatedWalletAmount = currentWalletAmount - transactionDetails.totalTransactionamount;
        // console.log(updatedWalletAmount)
          const userDocRef = doc(db, COLLECTION.USER, userId);
        
          await setDoc(userDocRef, { walletAmount: updatedWalletAmount }, { merge: true });
          
          // console.log("Wupdated for", userId);
        } else {
          console.log("User not found:", phoneNumber);
        }
        

      setSubmitLoading(false);
      setIsModalOpen(false);  
    } else {
      console.error("Quantity must be greater than 0 for all added items");
      setSubmitLoading(false);
      setIsModalOpen(false);
    }
  } catch (error) {
    console.error("Error", error);
    setSubmitLoading(false);
    setIsModalOpen(false);
  }
};

const handleNothing = async () =>{
  const documentRef = doc(
    db,
    COLLECTION.TRANSACTIONS,
    updateTransIdRef.current
  );
  console.log(documentRef);

  const item = "None"
  const tamount = 0
  const status = "Completed"

  await updateDoc(documentRef, {
    ['itemsPicked']: item
  });

  await updateDoc(documentRef, {
    ['transactionAmount']: tamount
  });

  await updateDoc(documentRef, {
    ['cartingStatus']: status
  });

  isCloseCalledRef.current = true;
  // Inorder to stop the for loop wait till the execution is stoped
  setTimeout(() => {
    updateTransIdRef.current = 0;
    setCurrentImagesfirst(null)
    setCurrentImages(null);
    setIsModalLoading(false);
    setCameraGroup([]);
    setIsModalOpen(false);
  }, 500);
  console.log("bought nothing")
}


  useEffect(() => {
    if (menuItems.length > 0)
      setAddedItems([{ id: 1, selectedItem: menuItems[0], quantity: 0 }]);
  }, [menuItems]);

  useEffect(() => {
    if (cameraGroup && cameraGroup.length > 0) {
      fetchCameraImagesfirst(cameraGroup)
      fetchCameraImages(cameraGroup);
    }
  }, [cameraGroup]);

  return (
    <Layout
      transactionTableHeading={transactionTableHeading}
      transactionTableData={tableData}
      isTableLoading={isTableLoading}
      currentPage={currentPage}
      totalLength={totalLength}
      handlePageChange={handlePageChange}
      handlePageSize={handlePageSize}
      handleSearchQuery={handleSearchQuery}
      handleTableSorting={handleTableSorting}
      updateTransId={updateTransId}
      setUpdateTransId={setUpdateTransId}
      fetchImages={fetchImages}
      currentImages={currentImages}
      isModalOpen={isModalOpen}
      setIsModalOpen={setIsModalOpen}
      onCloseModal={onCloseModal}
      isModalLoading={isModalLoading}
      menuItems={menuItems}
      addedItems={addedItems}
      setAddedItems={setAddedItems}
      handleAddItem={handleAddItem}
      handleDeleteItem={handleDeleteItem}
      onChangeValue={onChangeValue}
      handleSubmit={handleSubmit}
      validationErrors={validationErrors}
      submitLoading={submitLoading}
      fromDate={fromDate}
      setFromDate={setFromDate}
      toDate={toDate}
      setToDate={setToDate}
      onClickDateSearch={onClickDateSearch}
      images={images}
      currentImagesfirst={currentImagesfirst}
      handleNothing={handleNothing}
    />
  );
};

export default CartingTool;
