import { Button, makeStyles } from "@material-ui/core";
import React, { useState } from "react";
import { CustomTable, SearchTableInput } from "../../../components";
import { useNavigate } from "react-router-dom";
import { routes } from "../../../routing/routes";
import { vendorTableHeading } from "../../../assets/lib/vendor";

const customStyle = makeStyles({
  root: {
    padding: "20px 40px",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    gap: "30px",
    overflow: "hidden",
  },
  buttonContainer: {
    height: "50px",
    display: "flex",
    justifyContent: "space-between",
  },
  actionBtn: {
    display: "flex",
    gap: "30px",
  },
   // Media query mobile screens
   '@media (max-width: 390px)': {
    root: {
      padding: "5px 5px", 
      gap: "45px",         
    },
    buttonContainer: {
      gap: "5px",
      flexWrap: "wrap",    
      marginLeft:"0px"     
    },
    actionBtn: {
      gap: "10px", 
      marginLeft:"-1px",   
      width:"138px",
      height:"40px"
    },
  },
});

const Layout = (props) => {
  const {
    vendorTableData,
    isTableLoading,
    currentPage,
    totalLength,
    handlePageChange,
    handlePageSize,
    handleSearchQuery,
    handleTableSorting,
  } = props;

  const classes = customStyle();
  const navigate = useNavigate();
  const actions = [
    {
      label: "View",
      onClick: (row) => {
        navigate(`${routes.VENDOR_INFO}/${row.id}`, {
          state: { add: false, edit: false },
        });
      },
      bgColor: "#2196F3",
    },
    {
      label: "Edit",
      onClick: (row) => {
        navigate(`${routes.VENDOR_INFO}/${row.id}`, {
          state: { add: false, edit: true },
        });
      },
      bgColor: "#4CAF50",
    },
  ];

  return (
    <div className={classes.root}>
      <div className={classes.buttonContainer}>
        <SearchTableInput
          tableHeading={vendorTableHeading}
          handleSearchQuery={handleSearchQuery}
        />
        <div className={classes.actionBtn}>
          <Button
            variant="contained"
            style={{
              color: "white",
              backgroundColor: "#FF5722",
              padding: "12px 24px",
              borderRadius: "10px",
            }}
            onClick={() =>
              navigate(routes.VENDOR_INFO, {
                state: { add: true, edit: false },
              })
            }
          >
            Add Vendor
          </Button>
        </div>
      </div>
      <CustomTable
        columns={vendorTableHeading}
        data={vendorTableData}
        actions={actions}
        isLoading={isTableLoading}
        currentPage={currentPage}
        totalLength={totalLength}
        handlePageChange={handlePageChange}
        handlePageSize={handlePageSize}
        handleTableSorting={handleTableSorting}
      />
    </div>
  );
};

export default Layout;
