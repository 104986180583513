import React, { useState } from "react";
import { makeStyles } from "@material-ui/core";

const customStyle = makeStyles({
  errorContainer: {
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    textAlign: "center",
    justifyContent: "center",
  },
});

function ErrorAdminNavigationPage() {
  const classes = customStyle();

  return (
    <main className="root-layout">
      <div className="flex-direction-column width-100-per height-100-per">
        <div className={classes.errorContainer}>
          <h1>An error occured! </h1>
          <h4>Could not find this page</h4>
        </div>
      </div>
    </main>
  );
}

export default ErrorAdminNavigationPage;
