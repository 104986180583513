import React, { useEffect, useRef, useState } from "react";
import Layout from "./Layout";
import useTableFunction from "../../../hooks/useTableFunction";
import { db } from "../../../config/firebase";
import { createInitialForm } from "../../../common/common";
import { inventoritialForm } from "../../../common/common";
import { inventoryPodData } from "../../../assets/lib/vendor";
import { inventoryProductsData } from "../../../assets/lib/inventoryProduts.js";
import { COLLECTION, COLLECTION_COUNTER ,SESSION} from "../../../data/constant";
import {
    addDoc,
    collection,
    doc,
    getDoc,
    getDocs,
    query,
    updateDoc,
    setDoc
  } from "firebase/firestore";

const VendorManageInventory = () => {
  const [podSelected, setPodSelected] = useState(null);
  const [podMenu, setPodMenu] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpenForProduct, setIsModalOpenForProduct] = useState(false);
  const [inventoryState, setInventoryState] = useState(createInitialForm(inventoryPodData));
  const [productState, setProductState] = useState(createInitialForm(inventoryProductsData));
  const [productMenu, setProductMenu] = useState([]);
  const [productData, setProductData] = useState([]);

  

  useEffect(() => {
    const fetchVendorProductData = async () => {

      const sessionId = localStorage.getItem(SESSION.VENDOR_SESSION_ID);
      if (!sessionId) {
        throw new Error("Vendor session ID is not found in local storage.");
      }
      const vendorSessionDocRef = doc(db, COLLECTION.VENDOR_SESSION, sessionId);
        const vendorSessionDocSnap = await getDoc(vendorSessionDocRef);
        if (!vendorSessionDocSnap.exists()) {
          throw new Error("Vendor session document not found");
        }

        const collectionVendorRef = collection(db, COLLECTION.VENDOR);
        const baseVendorQuery = query(collectionVendorRef);
  
        const dataVendorSnapshot = await getDocs(baseVendorQuery);
  
        const collectionVendorData = dataVendorSnapshot.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
        }));
  
        setProductData(collectionVendorData);

    };
    fetchVendorProductData();
  }, []);
 // console.log("Yeah",productMenu)

const getProductInput = (event) => {
  const name = event.target.name;
  let value = event.target.value;

  const inputType = event.target.type;
  if (value && inputType === "number") {
    value = parseInt(value);
  }
  // if (errorFields.includes(name) && ![undefined, null, ""].includes(value)) {
  //   setErrorFields((prevState) => prevState.filter((key) => key !== name));
  // }
  setProductState((prevState) => ({ ...prevState, [name]: value }));

};

//console.log("ggggg",productData)

  const dynamicConditionCB = async () => {
    if (podSelected) {
      return {
        column: "id",
        operator: "==",
        query: podSelected,
      };
    }
  };

  const {
    tableData,
    isTableLoading,
    currentPage,
    totalLength,
    handlePageChange,
    handlePageSize,
    handleSearchQuery,
    handleTableSorting,
    refetchTableData,
  } = useTableFunction({
    collectionPath: COLLECTION.INVENTORY,
    dynamicCondition: dynamicConditionCB,
  });

  useEffect(() => {
    const fetchVendorData = async () => {
      let vendor = "";
      const sessionId = localStorage.getItem(SESSION.VENDOR_SESSION_ID);
      const docRef = doc(db, COLLECTION.VENDOR_SESSION, sessionId);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        const data = docSnap.data();
        vendor = data.vendorId;
        const docVendorRef = doc(db, COLLECTION.VENDOR, vendor);
        const docVendorSnap = await getDoc(docVendorRef);
        if (docVendorSnap.exists()) {
          const vendorData = docVendorSnap.data();
          setPodMenu(vendorData.podsForVendor);
          
          if (vendorData.podsForVendor && vendorData.podsForVendor.length > 0)
            setPodSelected(vendorData.podsForVendor[0]);

           const prodata = vendorData.vendorProducts // vendor product 
           setProductData(prodata)
            //console.log("vdata:",productData) 
        }
      }
    };
    fetchVendorData();
  }, []);

  useEffect(() => {
    refetchTableData();
  }, [podSelected]);

  const createVendorTable = () => {
    
    if (tableData && tableData.length > 0) {
      return Object.keys(tableData[0])
        .filter((key) => key !== "id")
        .map((key) => ({ ...tableData[0][key], itemId: key }));
    }
    
    return [];
  };
  createVendorTable()

  const updateModalClicked = (row) => {
    setIsModalOpen(true);
    setInventoryState(row);
  };

  const getInventoryInput = (event) => {
    const name = event.target.name;
    let value = event.target.value;

    const inputType = event.target.type;
    if (value && inputType === "number") {
      value = parseInt(value);
    }

    setInventoryState((prevState) => ({ ...prevState, [name]: value }));
  };


  const handleSubmitModal = async (row) => {

   console.log("ccccccccccccc",inventoryState)
   
    const { itemId, ...inventory } = inventoryState;

    const vendorRef = doc(db, COLLECTION.INVENTORY, podSelected);

    const vendorDocSnap = await getDoc(vendorRef);
    if (vendorDocSnap.exists()) {
      await updateDoc(vendorRef, {
        ...vendorDocSnap.data(),
        [itemId]: inventory,
      });
      setIsModalOpen(false);
      setInventoryState(createInitialForm(inventoryPodData));
      refetchTableData();
    }
  };


// Function to remove an object from an array by a property value, returning a new array
// function removeObjectByPropertyNewArray(array, propertyName, valueToRemove) {
//   return array.filter(item => item[propertyName] !== valueToRemove);
// }
// Create a new array without the object with id 'task2'
// const updatedTasks = removeObjectByPropertyNewArray(tasks, 'id', 'task2');

// Log the new array
// console.log(updatedTasks);

const handledelete = async (row)=>{
try {
  const inventoryRef = doc(db, COLLECTION.INVENTORY, podSelected);
  const vendorDocSnap = await getDoc(inventoryRef);
  const vendorDocSnapData = vendorDocSnap.data();

  console.log("ORIGINAL",vendorDocSnapData)
  
  const selected = row.productId
  const targetId = selected;
  let foundKey = null;
  
  for (const key in vendorDocSnapData) {
    if (vendorDocSnapData.hasOwnProperty(key)) {
      if (vendorDocSnapData[key].productId === targetId) {
        foundKey = key;
        break; 
       // Exit loop once the match is found
      }
    }
  }
  
  console.log("KEY",foundKey)
  delete vendorDocSnapData[foundKey];
  const final = vendorDocSnapData
  console.log("FINAL",final)

 await setDoc(inventoryRef, final);

} catch (error) {
 console.log("error", error);
}
refetchTableData()
}


  const handleSubmitModalforProducts = async (row) => {
      //  console.log("previous product menu",productMenu) 
      //  console.log("previous product state",productState)

      //total price
       const quant = productState.quantity
      // console.log(quant)
       const price = productMenu.unitPrice
      // console.log(price)
       const total = price*quant
      // console.log(total)

      //expiry
       const expi = productMenu.expiry
      // console.log(expi)
       var date = new Date();
       date.setDate(date.getDate() + expi)
       const dafinal = date.toDateString()
      // console.log(dafinal)

      delete productMenu.comments
      //console.log("product menu",productMenu) 

      delete productState.productName
      delete  productState.unitPrice
     //console.log("product state",productState)

      let join = {...productMenu,...productState}
      console.log("joined objects",join)

      join["expiry"] = dafinal //changed expiry value
      join["unitPrice"] = total //changes price value

      //console.log(join)

    try {

       const inventoryRef = doc(db, COLLECTION.INVENTORY, podSelected);//for inventory
       const inventoryDocSnap = await getDoc(inventoryRef)
       const poddata = inventoryDocSnap.data();

       const already  = Object.values(poddata);
       console.log("already",already);

       const entered  = join
       console.log("entered",entered)

       const mergedobj = already.concat(entered)
       const objectified ={...mergedobj};
       
       console.log("final",objectified)
  
      await setDoc(inventoryRef, objectified);
     
    } catch (error) {
      console.log("error", error);
    }

    setProductState(createInitialForm(inventoryProductsData));
    setIsModalOpenForProduct(false);
    refetchTableData()

  };

  return (
    <Layout
      podMenu={podMenu}
      podSelected={podSelected}
      setPodSelected={setPodSelected}
      refetchTableData={refetchTableData}
      itemsData={createVendorTable()}
      isTableLoading={isTableLoading}
      currentPage={currentPage}
      totalLength={totalLength}
      handlePageChange={handlePageChange}
      handlePageSize={handlePageSize}
      handleSearchQuery={handleSearchQuery}
      handleTableSorting={handleTableSorting}
      isModalOpen={isModalOpen}
      updateModalClicked={updateModalClicked}
      inventoryState={inventoryState}
      setIsModalOpen={setIsModalOpen}
      handleSubmitModal={handleSubmitModal}
      getInventoryInput={getInventoryInput}

      isModalOpenForProduct={isModalOpenForProduct}
      setIsModalOpenForProduct={setIsModalOpenForProduct}
      productState={productState}
      handleSubmitModalforProducts={handleSubmitModalforProducts}
      getProductInput={getProductInput}
      productMenu={productMenu}
      setProductMenu={setProductMenu}
      productData={productData}
      handledelete={handledelete}

    />
  );
};

export default VendorManageInventory;
