import React from "react";
import { useState } from "react";
import {
  createInitialForm,
  validateRequiredField,
} from "../../../common/common";
import { loginData } from "../../../assets/lib/login";
import { COLLECTION, SESSION } from "../../../data/constant";
import {
  Timestamp,
  addDoc,
  collection,
  getDocs,
  query,
  where,
} from "firebase/firestore";
import { db } from "../../../config/firebase";
import { useNavigate } from "react-router-dom";
import { routes } from "../../../routing/routes";
import Layout from "../UserLogin/Layout";

const VendorLogin = () => {
  const [loginState, setLoginState] = useState(createInitialForm(loginData));
  const [loginFailedText, setLoginFailedText] = useState("");
  const [errorFields, setErrorFields] = useState([]);
  const [submitLoading, setSubmitLoading] = useState(false);

  const navigate = useNavigate();

  const getLoginInput = (event) => {
    const name = event.target.name;
    let value = event.target.value;
    const inputType = event.target.type;
    if (value && inputType === "number") {
      value = parseInt(value);
    }
    if (errorFields.includes(name) && ![undefined, null, ""].includes(value)) {
      setErrorFields((prevState) => prevState.filter((key) => key !== name));
    }
    setLoginState((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleLogin = async () => {
    const error = validateRequiredField(loginData, loginState);
    setErrorFields(error);

    if (error.length === 0) {
      try {
        setSubmitLoading(true);
        const collectionRef = collection(db, COLLECTION.VENDOR);
        const baseQuery = query(
          collectionRef,
          where("phoneNumber", "==", loginState.phoneNumber)
        );
        const querySnapshot = await getDocs(baseQuery);

        if (!querySnapshot.empty) {
          const docSnap = querySnapshot.docs[0];
          const docData = { ...docSnap.data(), id: docSnap.id };
          if (docData.alpin === loginState.alpin) {
            // setLoginFailedText("Login successfull");
            const sessionCollectionRef = collection(
              db,
              COLLECTION.VENDOR_SESSION
            );
            const addedDocRef = await addDoc(sessionCollectionRef, {
              phoneNumber: docData.phoneNumber,
              sessionStart: Timestamp.now(),
              vendorId: docData.id,
            });
            setSubmitLoading(false);
            localStorage.setItem(SESSION.VENDOR_SESSION_ID, addedDocRef.id);
            navigate(routes.VENDOR_PODS);
          } else {
            setSubmitLoading(false);
            setLoginFailedText("Incorrect ALPIN");
          }
        } else {
          setSubmitLoading(false);
          setLoginFailedText("No phone number found");
        }
      } catch (error) {
        setSubmitLoading(false);
        console.log("error", error);
      }
    }
  };

  return (
    <Layout
      loginState={loginState}
      loginFailedText={loginFailedText}
      errorFields={errorFields}
      getLoginInput={getLoginInput}
      handleLogin={handleLogin}
    />
  );
};

export default VendorLogin;
