import { Button, makeStyles } from "@material-ui/core";
import React, { useState } from "react";
import { CustomTable, SearchTableInput } from "../../../components";
import moment from "moment/moment";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import UpdateModal from "./UpdateModal";
import { useMediaQuery } from '@mui/material';

const customStyle = makeStyles({
  root: {
    padding: "20px 40px",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    gap: "30px",
    overflow: "hidden",
  },
  buttonContainer: {
    display: "flex",
    gap: "20px",
    justifyContent: "end",
    alignItems: "center",
  },
  dash: {
    border: "0.5px solid #000",
    backgroundColor: "black",
    height: "1px",
    width: "10px",
  },
  datePicker: {
    width: "170px",
  },
  '@media (max-width: 390px)': { 
    root: {
      padding: "5px 5px", // Adjust padding for smaller screens
      gap: "10px", // Reduce gap for better fit
    },
    buttonContainer: {
      justifyContent: "left",
      display:"flex",
      gap: "10px",
      marginTop:"10px"
    },
    dash: {
      border: "0.5px solid #000",
      backgroundColor: "black",
      height: "1px",
      width: "1px",
    },
    datePicker: {
      width: "100%", // Make date picker full width
    },
  },
});

const Layout = (props) => {
  const {
    transactionTableHeading,
    transactionTableData,
    isTableLoading,
    currentPage,
    totalLength,
    handlePageChange,
    handlePageSize,
    handleSearchQuery,
    handleTableSorting,
    updateTransId,
    setUpdateTransId,
    fetchImages,
    currentImages,
    isModalOpen,
    setIsModalOpen,
    onCloseModal,
    isModalLoading,
    menuItems,
    addedItems,
    setAddedItems,
    handleAddItem,
    handleDeleteItem,
    onChangeValue,
    handleSubmit,
    validationErrors,
    submitLoading,
    fromDate,
    setFromDate,
    toDate,
    setToDate,
    onClickDateSearch,
    images,
    currentImagesfirst,
    handleNothing
  } = props;

  const classes = customStyle();
  const isPhone = useMediaQuery('(max-width: 390px)');

  const actions = [
    {
      label: "Update",
      onClick: (row) => {
        fetchImages(row);
        setIsModalOpen(true);
      },
      bgColor: "#2196F3",
    },
  ];
 // const filteredTransactions = transactionTableData.filter(transac => transac.authStatus === "AUTH1");
  return (
    <>
      <div className={classes.root}>
        <div className="flex justify-content-space-between" style={{ display: 'flex', flexDirection: isPhone ? 'column' : 'row', alignItems: isPhone ? 'flex-start' : 'center' }}>
          <SearchTableInput
            tableHeading={transactionTableHeading}
            handleSearchQuery={handleSearchQuery}
            width={"200px"}
          />
          <div className={classes.buttonContainer}>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DatePicker
                label="From Date"
                value={fromDate}
                onChange={(newValue) => setFromDate(newValue)}
                className={classes.datePicker}
                sx={{
                  '& .MuiInputBase-root': {
                    fontSize: isPhone ? '0.50rem' : 'auto', 
                    maxWidth: isPhone ? '70%' : 'auto', 
                  },
                  '& .MuiInputLabel-root': {
                    fontSize: isPhone ? '0.75rem' : 'auto', 
                  },
                  
                }}
              />
              <span className={classes.dash} />
              <DatePicker
                label="To Date"
                value={toDate}
                onChange={(newValue) => setToDate(newValue)}
                className={classes.datePicker}
                sx={{
                  '& .MuiInputBase-root': {
                    fontSize: isPhone ? '0.50rem' : 'auto', 
                    maxWidth: isPhone ? '70%' : 'auto', 
                  },
                  '& .MuiInputLabel-root': {
                    fontSize: isPhone ? '0.75rem' : 'auto', 
                  },
                  marginLeft:isPhone ? '-60px' : 'auto',
                }}
              />
            </LocalizationProvider>
            <Button
              variant="contained"
              style={{
                color: 'white',
                backgroundColor: '#FF5722',
                padding: isPhone ? '6px 14px' : '12px 24px',
                borderRadius: '10px',
                marginLeft: isPhone ? '-50px' : 'auto',
                fontSize: isPhone ? '0.900rem' : '1rem', 
              }}
              onClick={onClickDateSearch}
            >
              Search
            </Button>
          </div>
        </div>
        <CustomTable
          columns={transactionTableHeading}
          data={transactionTableData}
          actions={actions}
          actionsHeader={"Update Transaction"}
          isLoading={isTableLoading}
          currentPage={currentPage}
          totalLength={totalLength}
          handlePageChange={handlePageChange}
          handlePageSize={handlePageSize}
          handleTableSorting={handleTableSorting}
        />
      </div>
      <UpdateModal
        isModalOpen={isModalOpen}
        isLoading={isModalLoading}
        onClose={onCloseModal}
        currentImages={currentImages}
        menuItems={menuItems}
        addedItems={addedItems}
        setAddedItems={setAddedItems}
        handleAddItem={handleAddItem}
        handleDeleteItem={handleDeleteItem}
        onChangeValue={onChangeValue}
        handleSubmit={handleSubmit}
        validationErrors={validationErrors}
        submitLoading={submitLoading}
        images={images}
        currentImagesfirst={currentImagesfirst}
        handleNothing={handleNothing}
      />
    </>
  );
};

export default Layout;
